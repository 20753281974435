<template src="./index.tpl.html" />

<script>
  import BranchStageAccess from 'models/BranchStageAccess'
  import modal from 'components/modal'
  export default {
    props: ['stage', 'branch', 'branch_stage'],
    data() {
      return {
        accesses: [],
        loading: true
      }
    },
    methods: {
      getSessionData() {
        BranchStageAccess.findAll({ 
          branch_stage_id: this.branch_stage.id,  
          session_data: true
        }, { bypassCache: true, cacheResponse: false })
          .then(accesses => this.setAccesses(accesses))
          .catch(() => {})// TODO manage errors
          .then(() => this.loading = false)
      },
      setAccesses(accesses) {
        this.accesses = accesses.map(access => {
          let begin = new Date(access.created_at).getTime(),
            end = (access.expired_at ? new Date(access.expired_at) : new Date()).getTime()
          return Object.assign(Object.create(access), {
            begin,
            end: end,
            duration: end - begin,
            tab_focused_total_ms: this.getTabFocusedTime(access),
            user_active_total_ms: this.getUserActiveTime(access),
            playback_total_ms: this.getPlayingTime(access),
            tab_focused_periods: access.sessions.reduce((set, session) => {
              return set.concat(session.tab_focused_periods)
            }, []),
            playback_periods: access.sessions.reduce((set, session) => {
              return set.concat(session.playback_periods)
            }, []),
            user_active_periods: access.sessions.reduce((set, session) => {
              return set.concat(session.user_active_periods)
            }, []),
          })
        })
      },
      getLeft({ duration, begin }, period) {
        return ((period[0] - begin) / duration) * 100 + "%"
      },
      getWidth({ duration }, period) {
        return ((period[1] - period[0])/duration) * 100 + "%"
      },
      getTabFocusedTime(access) {
        return access.sessions.reduce((sum, session) => {
          return sum + session.tab_focused_total_ms
        }, 0)
      },
      getPlayingTime(access) {
        return access.sessions.reduce((sum, session) => {
          return sum + session.playback_total_ms
        }, 0)
      },
      getUserActiveTime(access) {
        return access.sessions.reduce((sum, session) => {
          return sum + session.user_active_total_ms
        }, 0)
      }
    },
    created() {
      this.getSessionData()
    },
    components: {
      modal
    }
  }
</script>

<style lang="less" src="./index.less" />