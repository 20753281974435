class ContextCursor {
  constructor(parent, dimensions, scale) {
    let h = dimensions.height,
        l = Math.sqrt(Math.pow(h, 2) / 2)
    this.g = parent.g.append('g')
      .attr('class', 'cursor')
      .attr('transform', 'translate(0,0)')
    this.g.append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('transform', `rotate(45 0 0)`)
      .attr('width', l)
      .attr('height', l)
    this.scale = scale
  }

  redraw(time) {
    let x = this.scale.x(time)
    this.g.attr('transform', `translate(${x},0)`)
  }
}

export default {
  build(...args) {
    return new ContextCursor(...args)
  }
}
