<template>
  <div class="tag-update">
    <div v-if="diff.begin">
      <span class="label">Begin Time:</span>
      <span>{{tp.begin | secondsDisplay}} <i class="fa fa-angle-double-right gray-lt"></i> {{tag.begin | secondsDisplay}}</span>
    </div>
    <div v-if="diff.end">
      <span class="label">End Time:</span>
      <span>{{tp.end | secondsDisplay}} <i class="fa fa-angle-double-right gray-lt"></i> {{tag.end | secondsDisplay}}</span>
    </div>
    <div v-if="diff.category">
      <span class="label">Category:</span>
      <span>{{categoryTitle(tp.tag_category_id)}} <i class="fa fa-angle-double-right gray-lt"></i> {{categoryTitle(tag.tag_category_id)}}</span>
    </div>
    <div v-if="diff.short">
      <span class="label">Short Description:</span>
      <span>{{tp.description_short}} <i class="fa fa-angle-double-right gray-lt"></i> {{tag.description_short}}</span>
    </div>
    <div v-if="diff.full">
      <div class="label">Full Description</div>
      <div>{{tp.description_full}} <i class="fa fa-angle-double-right gray-lt"></i></div>
      <div>{{tag.description_full}}</div>
    </div>
    <div v-if="diff.type">
      <span class="label">Type:</span>
      <span>{{tp.type}} <i class="fa fa-angle-double-right gray-lt"></i> {{tag.type}}</span>
    </div>
    <div v-if="diff.channel">
      <span class="label">channel:</span>
      <span>{{tp.channel}} <i class="fa fa-angle-double-right gray-lt"></i> {{tag.channel}}</span>
    </div>
    <div v-if="diff.is_omitted">
      <span class="label">Omitted:</span>
      <span>{{tp.is_omitted}} <i class="fa fa-angle-double-right gray-lt"></i> {{tag.is_omitted}}</span>
    </div>
    <div v-if="diff.spoiler">
      <span class="label">spoiler:</span>
      <span>{{tp.is_spoiler}} <i class="fa fa-angle-double-right gray-lt"></i> {{tag.is_spoiler}}</span>
    </div>
    <div v-if="diff.background">
      <span class="label">background:</span>
      <span>{{tp.is_background}} <i class="fa fa-angle-double-right gray-lt"></i> {{tag.is_background}}</span>
    </div>
    <div v-if="diff.plot">
      <span class="label">plot:</span>
      <span>{{tp.is_plot_critical}} <i class="fa fa-angle-double-right gray-lt"></i> {{tag.is_plot_critical}}</span>
    </div>
  </div>
</template>

<script>

  import TagCategory from 'models/TagCategory'

  export default {
    props: ['tag', 'tagPrev'],
    data() {
      return {
        tp: this.tagPrev
      }
    },
    methods: {
      categoryTitle(id) {
        let category = TagCategory.get(id)
        return category ? category.title : id
      }
    },
    computed: {
      diff() {
        let t1 = this.tag, t2 = this.tagPrev
        return {
          is_omitted: t1.is_omitted !== t2.is_omitted,
          begin: t1.begin !== t2.begin,
          end: t1.end !== t2.end,
          category: t1.tag_category_id !== t2.tag_category_id,
          short: t1.description_short !== t2.description_short,
          full: t1.description_full !== t2.description_full,
          type: t1.type !== t2.type,
          channel: t1.channel !== t2.channel,
          spoiler: t1.is_spoiler !== t2.is_spoiler,
          background: t1.is_background !== t2.is_background,
          plot: t1.is_plot_critical !== t2.is_plot_critical
        }
      }
    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">

  .tag-update {
    font-size: 0.9em;
    .label {
      font-weight: 500;
    }
  }

</style>

<!-- "user_id": "539d0b29-ffa9-4211-9209-352c4be3efdf", "tag_category_id": 15, "description_short": "Other thing", "description_full": "Got it!", "begin": "48.823573", "end": "65.472", "type": "audiovisual", "channel": null, "is_spoiler": false, "is_background": false, "is_plot_critical": false, "is_archived": false, "created_at": "2017-05-02T15:50:34.974Z", "updated_at": "2017-05-02T15:50:34.974Z", "working_id": 1 -->
