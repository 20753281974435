<template src="./index.tpl.html" />

<script>
  import api from 'api'
  import toast from 'services/toast'
  import BranchStage from 'models/BranchStage'

  import deltas from 'components/branchStage/deltas'
  import actions from 'components/branchStage/actions'

  export default {
    props: ['branch_stage'],
    data() {
      return {
        user: '',
        show: false,
        user_committed: '',
        show_status: false
      }
    },
    methods: {
      status() {
        let { committed_at, task } = this.branch_stage
        return committed_at ? 'Committed'
          : !task || task.status === 'started' ? 'In Progress'
            : task.status === 'error' ? 'Error' : 'Loading'
      },
      enterable() {
        return !this.branch_stage.task
      },
      render() {
        this.$forceUpdate()
      },
      watchBranchStageStatus() {
        if (this.branch_stage.task) {
          this.timeout = setTimeout(() => {
            this.branch_stage.DSRefresh()
              .catch(error => {
                if (error.status === 404) {
                  toast("A revision you were watching has been deleted. Please refresh this page", { theme: 'error' })
                  throw error
                }
              })
              .then(() => {
                this.render()
                this.watchBranchStageStatus()
              })
          }, 10000)
        }
      }
    },
    computed: {
      title() {
        return this.branch_stage.stage.title
      }
    },
    components: {
      actions,
      deltas
    },
    created() {
      api.hub.$on(BranchStage.getSocketKey(this.branch_stage), this.render)
      this.watchBranchStageStatus()
    },
    beforeDestroy() {
      api.hub.$off(BranchStage.getSocketKey(this.branch_stage), this.render)
      clearTimeout(this.timeout)
    }
  }
</script>

<style src="./index.less" lang="less" />
