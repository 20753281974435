import api from 'api'

const BranchStage = api.defineResource({
  name: 'BranchStage',
  endpoint: 'tagging/branch-stages',
  relations: {
    hasMany: {
      Tag: {
        localField: 'tags',
        foreignKey: 'working_id'
      },
      BranchStageAccess: {
        localField: 'accesses',
        foreignKey: 'branch_stage_id'
      }
    },
    belongsTo: {
      Stage: {
        localField: 'stage',
        localKey: 'stage_id'
      },
      Branch: {
        localField: 'branch',
        localKey: 'branch_id'
      },
      BranchStage: {
        localField: 'parent',
        localKey: 'parent_id'
      },
      User: [
        {
          localField: 'committer',
          localKey: 'committed_by'
        },
        {
          localField: 'creator',
          localKey: 'created_by'
        }
      ]
    },
    hasOne: {
      BranchStage: {
        localField: 'child',
        foreignKey: 'parent_id'
      }
    }
  },
  methods: {
    commit() {
      return this.DSDetail('commit').put(this).then(instance => {
        return BranchStage.safeInject(instance)
      })
    },
    uncommit() {
      return this.DSDetail('uncommit').put(this).then(instance => {
        return BranchStage.safeInject(instance)
      })
    },
    align(params) {
      return this.DSDetail('align').post(params)
    },
    getAccesses(include_expired) {
      return this.accesses.filter(access => !access.is_revoked && (include_expired || !access.expired_at))
    },
    updateComments() {
      return this.DSUpdate({ comments: this.comments }, { method: 'PATCH' })
    }
  },
  afterFind(resource, attrs, cb) {
    cb(null, attrs)
    api.hub.$emit(BranchStage.getSocketKey(attrs))
  },
  afterFindAll(resource, attrs, cb) {
    cb(null, attrs)
    api.hub.$emit('branch_stage.findAll')
  },
  afterLoadRelations(resource, attrs, cb) {
    cb(null, attrs)
    api.hub.$emit('branch_stage.loadRelations')
  },
  afterUpdate(resource, attrs, cb) {
    cb(null, attrs)
    api.hub.$emit(BranchStage.getSocketKey(attrs))
  },
  afterCreate(resource, attrs, cb) {
    cb(null, attrs)
    api.hub.$emit(BranchStage.getSocketKey(attrs))
  },
  afterEject(resource, attrs) {
    attrs = Array.isArray(attrs) ? attrs : [attrs]
    attrs.forEach(branch_stage => {
      api.hub.$emit(`branch_stage.${BranchStage.getSocketKey(branch_stage)}`)
      api.hub.$emit(`branch.${branch_stage.branch_id}.branch_stages`)
    })
  },
  watchChanges: false

})

BranchStage.safeInject = branch_stage => {
  BranchStage.inject(branch_stage)
  api.hub.$emit(`branch_stages`)
  api.hub.$emit(`${BranchStage.getSocketKey(branch_stage)}`)
  api.hub.$emit(`branch.${branch_stage.branch_id}.branch_stages`)
}

BranchStage.getSocketKey = attrs => {
  return `branch_stage.${attrs.branch_id}.${attrs.stage_id}`
}

export default BranchStage
