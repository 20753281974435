import api from 'api'
import StageAccess from 'models/StageAccess'

const User = api.defineResource({
  name: 'User',
  endpoint: 'tagging/users',
  cacheResponse: true,
  relations: {
    hasMany: {
      StageAccess: {
        localField: 'stage_accesses',
        foreignKey: 'user_id'
      }
    }
  },
  methods: {
    fullName() {
      if (this.first_name || this.last_name){
        return `${this.first_name} ${this.last_name}`
      }
      return this.email
    }
  },
  watchChanges: false
})

User.me = () => {
  return User.find('me')
}

export default User
