<script>

  import Auth from 'services/api/auth'
  import Branch from 'models/Branch'
  import Stage from 'models/Stage'

  import branchCompleted from 'components/branch/detail/completed'
  import branchCheckedOut from 'components/branch/detail/checkedOut'
  import poster from 'components/poster'
  
  let limit = 15,
      order_by = '-committed_at'

  export default {
    data() {
      return {
        show: false,
        more: true,
        branches_past: [],
        branches_current: [],
        branches_pending: [],
        branches_past_loading: false
      }
    },
    resolve: {
      branches_current: () => Branch.findAll({
        filter: {
          branch_stages__committed_at__isnull: true,
          branch_stages__accesses__user_id: Auth.user.id,
          branch_stages__accesses__expired_at__isnull: true,
          branch_stages__accesses__is_revoked: false
        }
      }, { bypassCache: true }),
      stages: () => Stage.findAll()
    },
    methods: {
      getPendingBranches() {
        return Branch.findAll({
          filter: {
            committed_at__isnull: true,
            branch_stages__committed_by_id: Auth.user.id,
          },
          exclude: {
            branch_stages__stage_id__in: [11, 12, 13, 14]
          }
        }, { bypassCache: true }).then((branches_pending = []) => {
          this.branches_pending = branches_pending.filter(branch => {
            return !(this.branches_current.indexOf(branch) > -1)
          })
        })
      },
      getPastBranches() {
        this.branches_past_loading = true
        return Branch.findAll({
          filter: {
            committed_at__isnull: false,
            branch_stages__committed_by_id: Auth.user.id
          },
          order_by
          // limit, // Broken in sequelize
          // offset, // Broken in sequelize
        }, {
          bypassCache: true
        }).then(branches_past => {
          this.branches_past_all = branches_past
          this.showNext()
        })
          .catch(() => {})
          .then(() => this.branches_past_loading = false)
      },
      showNext() {
        let length = this.branches_past.length + limit
        this.branches_past = this.branches_past_all.slice(0, length)
        this.more = length < this.branches_past_all.length
      }
    },
    created() {
      this.branches_current = this.$route.meta.data.branches_current || []
      this.getPendingBranches()
      this.getPastBranches()
    },
    components: {
      branchCheckedOut,
      branchCompleted,
    }
  }

</script>
<template src="./index.tpl.html"></template>
<style src="./index.less" lang="less"></style>
