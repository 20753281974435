
<template src="./index.tpl.html"></template>

<script>

  import api from 'api'

  import toast from 'services/toast'

  import Stage from 'models/Stage'
  import BranchStage from 'models/BranchStage'

  import branchSummary from 'components/branch/detail/summary'
  import branchActions from 'components/branch/actions'
  import branchStage from 'components/branchStage'
  import userSelect from 'components/users/select'
  import dropdown from 'components/dropdown'

  export default {
    props: ['recording', 'branch'],
    data() {
      return {
        user: '',
        user_committed: '',
        show: false,
        expanded: false,
        detail_up: false,
        branch_stages: this.branch.branch_stages
      }
    },
    methods: {
      setBranchStages() {
        this.branch_stages = this.branch.branch_stages
      },
      createStage(stage) {
        BranchStage.create({
          branch_id: this.branch.id,
          stage_id: stage.id
        }).then(() => {
          this.branch_stages = this.branch.branch_stages
        }).catch(error => {
          toast(error.data.message, { theme: 'error' })
        })
      },
      getPriority() {
        return this.branch.priority && ['None', 'Low', 'Med', 'High', 'Urgent'][this.branch.priority]
      },
      nextStage() {
        return Stage.getNext(this.branch)
      },
      showNextStage() {
        return this.nextStage() && !this.branch.committed_at && !this.branch_stages.find(s => !s.committed_at) && !this.recording.is_source_files_archived
      },
      render() {
        this.$forceUpdate()
      }
    },
    computed: {
      tree() {
        let bss = [],
            branch_stage = this.branch_stages.find(bs => !bs.parent_id)
        while (branch_stage) {
          bss.push(branch_stage)
          branch_stage = branch_stage.child
        }
        return bss
      }
    },
    components: {
      branchSummary,
      branchActions,
      branchStage,
      userSelect,
      dropdown
    },
    created() {
      let id = this.branch.id
      api.hub.$on(`branch.${id}`, this.render)
      api.hub.$on(`branch.${id}.branch_stages`, this.setBranchStages)
      api.hub.$on(`recording.${this.recording.id}`, this.render)
    },
    beforeDestroy() {
      let id = this.branch.id
      api.hub.$off(`branch.${id}`, this.render)
      api.hub.$off(`branch.${id}.branch_stages`, this.setBranchStages)
      api.hub.$off(`recording.${this.recording.id}`, this.render)
    }

  }

</script>
<style src="./index.less" lang="less"></style>
