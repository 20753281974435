import resolver from 'services/resolve'

let matched, index = 0

function beforeEnter(to, from, next) {
  let route
  if (matched === to.matched) {
    route = to.matched[++index]
  } else {
    matched = to.matched
    route = matched.find((r, i) => r !== from.matched[(index = i)])
  }
  let components = route && route.components,
      resolves = Object.keys(components)
        .map(key => resolveComponent(to, from, next, components[key] || {}))
  Promise.all(resolves)
    .then(() => next())
    .catch(onError(to, from, next))
}

function beforeUpdate(to, from, next) {
  resolveComponent(to, from, next, this.$options, this)
    .then(() => next())
    .catch(onError(to, from, next))
}

function resolveComponent(to, from, next, component, instance) {
  let { dataResolve, resolve } = component
  if (resolve || dataResolve) {
    let tree = Object.assign({}, resolve, dataResolve)
    return resolver.call({ to, from, next }, tree).then(data => {
      to.meta.data = to.meta.data || {}
      for (let key in data) {
        to.meta.data[key] = data[key]
        if (instance && dataResolve && (key in dataResolve)) instance[key] = data[key]
      }
    })
  }
  return Promise.resolve()
}

function onError(to, from, next) {
  return function(err) {
    next((!to.path || to.path === '/') ? '/hmm' : '/')
    console.error(err.message || err.msg || err, { theme: 'error' })
    return err
  }
}

export default {
  data() {
    let data = {}
    if (this.$options.dataResolve) {
      for (let key in this.$options.dataResolve) {
        data[key] = this.$route.meta.data[key]
      }
    }
    return data
  },
  beforeRouteEnter: beforeEnter,
  beforeRouteUpdate: beforeUpdate
}
