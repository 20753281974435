// Find Line of best fit by Least Squares Regression
export default function(values_x, values_y) {
  let sum_x = 0,
      sum_y = 0,
      sum_xy = 0,
      sum_xx = 0,
      count = 0

  /*
   * We'll use those variables for faster read/write access.
   */
  let x = 0,
      y = 0,
      i = values_x.length

  if (!i) {
    throw Error('No values found')
  } else if (i !== values_y.length) {
    throw Error('Values for x and y must have equal lengths')
  }

  /*
   * Calculate the sum for each of the parts necessary.
   */
  for (var v = 0; v < i; v++) {
    x = values_x[v]
    y = values_y[v]
    sum_x += x
    sum_y += y
    sum_xx += x * x
    sum_xy += x * y
    count++
  }

  /*
   * Calculate m and b for the formular:
   * y = x * m + b
   */
  let m = (count * sum_xy - sum_x * sum_y) / (count * sum_xx - sum_x * sum_x),
      b = (sum_y / count) - (m * sum_x) / count

  return {
    scale: m,
    translation: b
  }
}
