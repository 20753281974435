<template>
  <div class="maintenance">
    <div>
      <h1>Whoa there, Cowboy!</h1>
      <p>Looks like the system is having a moment! If visiting <a href="/">home</a> doesn't get you back after a while, let someone know!</p>
    </div>
  </div>
</template>

<script>
  export default {}
</script>

<style lang="less">
  .maintenance {
    position: fixed;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    div {
      text-align: center;
    }
  }
</style>
