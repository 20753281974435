import BrowserReport from 'services/browser_report'
import TaggingSession from 'models/TaggingSession'

let session = null

const Session = {
  // Report Frequency (set as interval on window)
  REPORT_FREQUENCY_MS: 100,

  getNewSession(session_id, access_id) {
    const now_ms = new Date().getTime()
    let last_user_active_period = [now_ms, now_ms]
    return {
      os: BrowserReport.os.name,
      os_version: BrowserReport.os.version,
      browser: BrowserReport.browser.name,
  
      id: session_id,
      access_id: access_id,
      started_at: new Date(),
      duration_ms: Session.REPORT_FREQUENCY_MS/2,
  
      last_tab_focused_state: false,
      last_tab_focused_period: null,
      tab_focused_periods: [],
  
      last_playing_state: false,
      last_playing_period: null,
      playback_periods: [],

      last_user_is_active: true,
      last_user_active_period,
      user_active_periods: [last_user_active_period],
    }
  },

  async report(payload, force_flush) {
    const {
      access_id, 
      session_id, 
      last_user_interaction,
      is_playing,
      is_tab_focused, 
    } = payload
    // If user is not working via an access, assume admin and skip reporting
    if (!access_id) return
    if (!session || session.id != session_id) {
      session = Session.getNewSession(session_id, access_id)
    }
  
    const now_ms = new Date().getTime()
    session.duration_ms = now_ms - session.started_at.getTime()
  
    // time user was "active" (player was playing, user was typing, clicking)
    const is_active = is_tab_focused && !(Math.abs(now_ms - last_user_interaction.getTime()) > (20 * 1000))
    const last_active = last_user_interaction.getTime()
    if (is_active) {
      if (session.last_user_is_active) {
        // prevent negative time reports
        session.last_user_active_period[1] = Math.max(session.last_user_active_period[1], last_active)
      } else {
        session.last_user_active_period = [last_active, last_active]
        session.user_active_periods.push(session.last_user_active_period)
      }
    }
    session.last_user_is_active = is_active

    // time browser tab was focused
    if (is_tab_focused) {
      if (session.last_tab_focused_state) {
        session.last_tab_focused_period[1] = now_ms
      } else {
        session.last_tab_focused_period = [now_ms, now_ms + Session.REPORT_FREQUENCY_MS/2]
        session.tab_focused_periods.push(session.last_tab_focused_period)
      }
    } else if (session.last_tab_focused_state) {
      force_flush = true
    }
    session.last_tab_focused_state = is_tab_focused
  
    // time player was playing
    if (is_playing) {
      if (session.last_playing_state) {
        session.last_playing_period[1] = now_ms
      } else {
        session.last_playing_period = [now_ms, now_ms + Session.REPORT_FREQUENCY_MS]
        session.playback_periods.push(session.last_playing_period)
      }
    }
    session.last_playing_state = is_playing
    
    if (force_flush) {
      TaggingSession.create(session)
    }
  }
}

export default Session
