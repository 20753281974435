const dom = {
  height(elem) {
    return isNaN(elem.offsetHeight)
      ? elem.document.documentElement.clientHeight
      : elem.offsetHeight
  },
  offsetTop(elem) {
    if (!elem.getBoundingClientRect) return
    return elem.getBoundingClientRect().top + dom.pageYOffset(elem)
  },
  pageYOffset(elem) {
    return isNaN(window.pageYOffset)
      ? elem.document.documentElement.scrollTop
      : elem.ownerDocument.defaultView.pageYOffset
  },
  hasClass(elem, klass) {
    klass = (klass || '').trim()
    return (` ${elem.className} `).indexOf(` ${klass} `) > -1
  },
  getByClass(klass) {
    return document.getElementsByClassName(klass)
  }
}

export default dom
