<template>
  <div class="player-wrap">
    <div class="player" @click="togglePlay">
      <video class="fullvideo" ref="video" :controls="controls" />
      <waiting v-if="loading"></waiting>
      <div class="fullplay" v-else-if="canplay">
        <i class="fa fa-fw fa-play"></i>
      </div>
    </div>
    <div class="player-wrap-after" :style="wrapStyle()"></div>
  </div>
</template>

<script>

  import api from 'services/api'
  import http from 'services/api/adapters/http'
  import playback from './lib/playback'
  import fullscreen from './lib/fullscreen'
  import waiting from './lib/waiting'

  Object.defineProperty(HTMLMediaElement.prototype, 'playing', {
    get() {
      return !!(this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2)
    }
  })

  export default {
    props: {
      source: String,
      type: String,
      controls: Boolean,
      speed: {
        type: Number,
        default: 1
      },
      speeds: {
        type: Array,
        default: () => [1 / 4, 1 / 2, 0.66, 3 / 4, 1, 5 / 4, 3 / 2, 2, 3]
      },
      initTime: {
        type: Number
      }
    },
    data() {
      return {
        loading: true,
        canplay: false,
        ratio: 9 / 16
      }
    },
    components: {
      waiting
    },
    methods: {

      // HLS Events
      // AUDIO_TRACKS_UPDATED:"hlsAudioTracksUpdated"
      // AUDIO_TRACK_LOADED:"hlsAudioTrackLoaded"
      // AUDIO_TRACK_LOADING:"hlsAudioTrackLoading"
      // AUDIO_TRACK_SWITCH:"hlsAudioTrackSwitch"
      // AUDIO_TRACK_SWITCHED:"hlsAudioTrackSwitched"
      // AUDIO_TRACK_SWITCHING:"hlsAudioTrackSwitching"
      // BUFFER_APPENDED:"hlsBufferAppended"
      // BUFFER_APPENDING:"hlsBufferAppending"
      // BUFFER_CODECS:"hlsBufferCodecs"
      // BUFFER_CREATED:"hlsBufferCreated"
      // BUFFER_EOS:"hlsBufferEos"
      // BUFFER_FLUSHED:"hlsBufferFlushed"
      // BUFFER_FLUSHING:"hlsBufferFlushing"
      // BUFFER_RESET:"hlsBufferReset"
      // DESTROYING:"hlsDestroying"
      // ERROR:"hlsError"
      // FPS_DROP:"hlsFpsDrop"
      // FPS_DROP_LEVEL_CAPPING:"hlsFpsDropLevelCapping"
      // FRAG_BUFFERED:"hlsFragBuffered"
      // FRAG_CHANGED:"hlsFragChanged"
      // FRAG_DECRYPTED:"hlsFragDecrypted"
      // FRAG_LOADED:"hlsFragLoaded"
      // FRAG_LOADING:"hlsFragLoading"
      // FRAG_LOAD_EMERGENCY_ABORTED:"hlsFragLoadEmergencyAborted"
      // FRAG_LOAD_PROGRESS:"hlsFragLoadProgress"
      // FRAG_PARSED:"hlsFragParsed"
      // FRAG_PARSING_DATA:"hlsFragParsingData"
      // FRAG_PARSING_INIT_SEGMENT:"hlsFragParsingInitSegment"
      // FRAG_PARSING_METADATA:"hlsFragParsingMetadata"
      // FRAG_PARSING_USERDATA:"hlsFragParsingUserdata"
      // INIT_PTS_FOUND:"hlsInitPtsFound"
      // KEY_LOADED:"hlsKeyLoaded"
      // KEY_LOADING:"hlsKeyLoading"
      // LEVEL_LOADED:"hlsLevelLoaded"
      // LEVEL_LOADING:"hlsLevelLoading"
      // LEVEL_PTS_UPDATED:"hlsLevelPtsUpdated"
      // LEVEL_SWITCH:"hlsLevelSwitch"
      // LEVEL_SWITCHED:"hlsLevelSwitched"
      // LEVEL_SWITCHING:"hlsLevelSwitching"
      // LEVEL_UPDATED:"hlsLevelUpdated"
      // MANIFEST_LOADED:"hlsManifestLoaded"
      // MANIFEST_LOADING:"hlsManifestLoading"
      // MANIFEST_PARSED:"hlsManifestParsed"
      // MEDIA_ATTACHED:"hlsMediaAttached"
      // MEDIA_ATTACHING:"hlsMediaAttaching"
      // MEDIA_DETACHED:"hlsMediaDetached"
      // MEDIA_DETACHING:"hlsMediaDetaching"
      // STREAM_STATE_TRANSITION:"hlsStreamStateTransition"
      // SUBTITLE_FRAG_PROCESSED:"hlsSubtitleFragProcessed"
      // SUBTITLE_TRACKS_UPDATED:"hlsSubtitleTracksUpdated"
      // SUBTITLE_TRACK_LOADED:"hlsSubtitleTrackLoaded"
      // SUBTITLE_TRACK_LOADING:"hlsSubtitleTrackLoading"
      // SUBTITLE_TRACK_SWITCH:"hlsSubtitleTrackSwitch"

      // Video Element Events
      // abort
      // canplay
      // canplaythrough
      // durationchange
      // emptied
      // ended
      // error
      // loadeddata
      // loadedmetadata
      // loadstart
      // pause
      // play
      // playing
      // progress
      // ratechange
      // seeked
      // seeking
      // stalled
      // suspend
      // timeupdate
      // volumechange
      // waiting

      init() {
        let video = this.$refs.video
        if (window.Hls.isSupported()) {
          this.load(this.source, { start: this.initTime || 0 })
          video.addEventListener('loadedmetadata', () => {
            if (video.videoHeight) {
              // this.ratio = video.videoHeight/video.videoWidth;
            }
            this.$emit('ready')
          })
          video.addEventListener('seeking', () => {
            this.progress()
          })
          video.addEventListener('timeupdate', () => {
            this.progress()
          })
          video.addEventListener('play', () => {
            this.startInterval()
            this.canplay = false
          })
          video.addEventListener('pause', () => {
            this.stopInterval()
            this.progress()
            this.canplay = true
            this.$emit('pause')
          })
          video.addEventListener('loadeddata', () => {
            this.loading = false
            this.canplay = true
          })
          video.addEventListener('error', (error) => {
            this.stopInterval()
            console.error('crashed: ', error)
            this.$emit('crash')
          })
        }
      },
      load(source, opts = {}) {
        let { video } = this.$refs
        video.autoplay = ('autoplay' in opts) ? opts.autoplay : video.playing
        if (this.hls) { this.hls.destroy() }
        this.progress(opts.start)
        this.loading = true
        this.hls = new window.Hls({
          startPosition: opts.start,
          maxFragLookUpTolerance: 0.7,
          xhrSetup(xhr, url) {
            if (url && url.indexOf(api.defaults.basePath) > -1 ) {
              xhr.setRequestHeader('Authorization', http.defaults.httpConfig.headers.Authorization)
            }
          },
        })
        this.hls.loadSource(source)
        this.hls.attachMedia(video)
        video.playbackRate = this.speed
      },
      keyEvent(event) {
        let video = this.$refs.video,
            playing = video.playing,
            shift = !!event.shiftKey,
            key = event.keyCode
        if ([32, 37, 38, 39, 40, 70].indexOf(key) > -1) {
          if (key === 32) {
            if (playing) {
              video.pause()
            } else {
              video.play()
            }
          } else if (key === 38) { // up arrow
            this.rate(true)
          } else if (key === 40) { // down arrow
            this.rate(false)
          } else if (key === 37) { // left arrow
            if (shift && playing) {
              video.pause()
            }
            this.time(Math.max(0, this.time() - (shift ? 0.025 : 3)))
          } else if (key === 39) { // right arrow
            if (shift) {
              if (!playing) {
                video.play()
              }
              setTimeout(() => video.pause(), 25)
              // The preference media.seekToNextFrame must be enabled to use the seekToNextFrame() method.
              // HTMLMediaElement.seekToNextFrame()
            } else {
              this.time(Math.max(0, this.time() + 3))
            }
          } else if (event.keyCode === 70) {
            if (event.metaKey) { return }
            return this.fullscreen(!this.fullscreen())
          }
          event.preventDefault()
          event.stopPropagation()
          return false
        }
      },
      play() {
        this.$refs.video.play()
      },
      pause() {
        this.$refs.video.pause()
      },
      togglePlay() {
        let vid = this.$refs.video
        vid.playing ? vid.pause() : vid.play()
      },
      rate(...args) {
        let speed = playback.call(this, this.$refs.video, this.speeds, ...args)
        this.$emit('speed', speed)
        return speed
      },
      time(time) {
        let { video } = this.$refs
        if (!video) return
        if (arguments.length) {
          video.currentTime = time
        }
        return video.currentTime
      },
      fullscreen(...args) {
        return fullscreen.call(this, this.$refs.video, ...args)
      },
      progress(time) {
        time = time || this.time()
        if (this.loading) { return }
        this.$emit('progress', time)
      },
      startInterval() {
        this.stopInterval()
        this.interval = setInterval(() => this.progress(), 40)
      },
      stopInterval() {
        clearInterval(this.interval)
      },
      isPlaying() {
        return this.$refs.video && this.$refs.video.playing
      },
      paused() {
        return this.$refs.video && this.$refs.video.paused
      },
      wrapStyle() {
        return {
          'padding-top': (this.ratio * 100) + '%'
        }
      }
    },
    watch: {
      speed(speed) {
        return this.rate(speed)
      }
    },
    mounted() {
      this.init()
    },
    beforeDestroy() {
      this.stopInterval()
      this.hls && this.hls.destroy()
    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./index.less" lang="less"></style>
