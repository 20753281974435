import Cookies from 'js-cookie'

import api from 'services/api'
// import Analytics from 'services/analytics'
import Identity from 'services/identity'

let redirect

class AuthServices {
  getAnonymousStatsId() {
    return Identity.browseware
  }

  onLogin(user, opts = {}) {
    if (opts.resuming_session) {
      // Analytics('user_resumed_session', opts)
    } else {
      // Analytics('user_logged_in', opts)
      // Analytics('user_login', opts)
    }

    // Redirects
    let cookie_redirect = Cookies.get('social-auth-redirect'),
        to = cookie_redirect || redirect || '/'
    Cookies.remove('social-auth-redirect')
    this.setRedirect()
    api.hub.$emit('auth.user.login')
    return {
      user,
      to
    }
  }

  onLogout(no_redirect, opts = {}) {
    // Clear Analytics && Resources
    // Analytics('user_logged_out', opts)
    // Analytics('user_logout', opts)
    api.clear()
    this.setRedirect()
    api.hub.$emit('auth.user.logout')
  }

  setRedirect(to) {
    redirect = to && to.fullPath
  }

  getRedirect() {
    return redirect
  }
}

export default new AuthServices()
