<script>

  import dom from 'services/dom'
  import time from 'services/time'
  import Tag from 'models/Tag'
  import Category from 'models/TagCategory'

  import Analytics from 'services/analytics'
  import categorySelector from 'components/tagCategory/selector'
  import field from 'components/form/field'

  export default {
    props: ['tag_attrs', 'branch_stage', 'time', 'recording'],
    data() {
      let tag = this.tag_attrs.id ? this.tag_attrs : Tag.newify(this.tag_attrs, this.branch_stage)
      return {
        tag,
        pending: false,
        error: undefined,
        begin: this.splayTagBeginTime(tag.begin),
        end: this.splayTagEndTime(tag.end),
        select_category: false,
        suggested_tags: undefined,
        suggested_tags_loading: false
      }
    },
    watch: {
      tag: function(n, o) {
        if (!o && n && !n.id) {
          setTimeout(() => {
            let el = dom.getByClass('edit-time-end')[0]
            return el && el.focus()
          }, 20)
        }
      },
      'begin.minutes': function(n, o) {
        this.begin.minutes = /^[0-9]{0,3}$/.test(+n) ? +n : o
        this.setTagBeginTime()
      },
      'begin.seconds': function(n, o) {
        this.begin.seconds = /^[0-9]{0,2}$/.test(+n) ? time.zero(+n, 2) : o
        this.setTagBeginTime()
      },
      'begin.milliseconds': function(n, o) {
        this.begin.milliseconds = /^[0-9]{0,3}$/.test(+n) ? time.zero(+n, 3) : o
        this.setTagBeginTime()
      },
      'end.minutes': function(n, o) {
        this.end.minutes = /^[0-9]{0,3}$/.test(+n) ? +n : o
        this.setTagEndTime()
      },
      'end.seconds': function(n, o) {
        this.end.seconds = /^[0-9]{0,2}$/.test(+n) ? time.zero(+n, 2) : o
        this.setTagEndTime()
      },
      'end.milliseconds': function(n, o) {
        this.end.milliseconds = /^[0-9]{0,3}$/.test(+n) ? time.zero(+n, 3) : o
        this.setTagEndTime()
      },
      time(time) {
        let el = document.activeElement
        if (el) {
          let attr = el.getAttribute('data-time-property')
          if (attr && attr === 'begin') {
            this.tag.begin = time
            this.splayTagBeginTime(time)
          } else if (attr && attr === 'end') {
            this.tag.end = time
            this.splayTagEndTime(time)
          }
        }
      }
    },
    computed: {
      all_categories() {
        return Category.getAll()
      }
    },
    methods: {
      toTime(time) {
        this.$emit('toTime', time)
      },
      setCategory(cat) {
        this.tag.setCategory(cat)
        this.select_category = false
        this.clearError()
      },
      splayTagBeginTime(s) {
        this.begin = {
          minutes: time.seconds.toM(s),
          seconds: time.seconds.toS(s, true),
          milliseconds: time.seconds.toMs(s, true)
        }
        return this.begin
      },
      setTagBeginTime() {
        this.tag.begin = this.convertToSec(this.begin)
      },
      splayTagEndTime(s) {
        this.end = {
          minutes: time.seconds.toM(s),
          seconds: time.seconds.toS(s, true),
          milliseconds: time.seconds.toMs(s, true)
        }
        return this.end
      },
      setTagEndTime() {
        this.tag.end = this.convertToSec(this.end)
      },
      convertToSec(obj) {
        return (+obj.minutes * 60) + +obj.seconds + (+obj.milliseconds / 1000)
      },
      pin(key) {
        let target = document.getElementsByClassName(`edit-time-${key}`)[0]
        return target && target.focus()
      },
      omit() {
        let proceed = window.confirm('Are you sure this tag is not found in this recording?')
        if (proceed) {
          this.tag.is_omitted = true
        }
      },
      destroy() {
        let proceed = window.confirm('Are you sure you want to delete this tag?')
        if (proceed) {
          this.tag.DSDestroy({ params: { branch_stage_id: this.branch_stage.id } })
            .then(() => {
              this.exit_action = 'destroy'
              this.$emit('close', { begin: 0 })
            })
        }
      },
      save() {
        if (this.pending) { return }
        this.pending = true
        this.tag.persist(this.branch_stage, this.recording)
          .then(([tag, updates]) => {
            this.tag_persisted = tag
            this.exit_action = 'save'
            this.$emit('close', updates)
          })
          .catch(error => {
            console.error(error)
            this.error = error.data
          })
          .finally(() => this.pending = false)
      },
      cancel() {
        this.canceled = true
        if (this.tag.id && this.tag.DSHasChanges()) {
          this.tag.DSRevert()
        }
        this.exit_action = 'cancel'
        this.$emit('close')
      },
      keydown(event) {
        if (event.keyCode === 27) {
          let proceed = !(!this.tag.id || this.tag.DSHasChanges())
          if (!proceed) {
            proceed = window.confirm('This tag has unsaved changes. Are you sure you want to proceed?')
          }
          if (proceed) {
            this.cancel()
          }
        } else if ((event.metaKey || event.ctrlKey) && event.keyCode === 13) {
          this.save()
        }
      },
      clearError() {
        this.error = null
      },
      useSuggestion(tag) {
        this.tag.begin = tag.begin
        this.splayTagBeginTime(tag.begin)
        this.tag.end = tag.end
        this.splayTagEndTime(tag.end)
      }
    },
    mounted() {
      if (!this.tag_attrs.id) {
        this.pin('end')
      }
      if (!this.tag.isActivable() && this.tag.tag_master_id) {
        this.suggested_tags = []
        this.suggested_tags_loading = true
        this.tag.findReferences().then(tag_instances => {
          this.suggested_tags = tag_instances
        }).catch(() => {
          // TODO Handle fail
        }).then(() => {
          this.suggested_tags_loading = false
        })
      }
    },
    created() {
      this.is_new_tag = !this.tag_attrs.id
      this.created_time = new Date().getTime() / 1000
      document.addEventListener('keydown', this.keydown)
    },
    beforeDestroy() {
      Analytics('user_edited_tag_v3', {
        is_new_tag: this.is_new_tag,
        duration_sec: (new Date().getTime() / 1000) - this.created_time,
        saved: !this.canceled,
        ... (this.tag_persisted || this.tag),
        exit_action: this.exit_action || ''
      })
      document.removeEventListener('keydown', this.keydown)
    },
    components: {
      categorySelector,
      field
    }
  }
</script>
<template src="./index.tpl.html"></template>
<style src="./index.less" lang="less"></style>
