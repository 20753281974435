<script>
  import api from 'api'

  import Auth from 'services/api/auth'

  import branchActions from 'components/branch/actions'
  import stage from 'components/stage'
  import dropdown from 'components/dropdown'

  export default {
    props: ['branch', 'refresh_token'],
    data() {
      return {
        me: Auth.user,
        show: false,
        stages: this.branch.getStageSet(),
        branch_stages: this.branch.getBranchStages()
      }
    },
    methods: {
      commit() {
        let confirm = window.confirm('Are you sure you want to commit & close this revision?')
        if (confirm) {
          this.branch.commit().then(() => this.refresh())
        }
      },
      setPriority(priority) {
        this.update({ priority })
      },
      pause(is_paused) {
        this.update({ is_paused })
      },
      update(payload) {
        return this.branch.DSUpdate(payload).then(() => this.refresh())
      },
      destroy() {
        let confirm = window.confirm('Are you sure you want to delete this revision?')
        if (confirm) {
          this.branch.DSDestroy()
            .then(() => this.refresh())
            .catch(err => alert(err.data.message))
        }
      },
      refresh() {
        this.show = false
        this.$emit('update')
      },
      repaint() {
        this.stages = this.branch.getStageSet()
        this.branch_stages = this.branch.getBranchStages()
      },
      getShowTitle(branch) {
        let work = branch.recording.work
        return work.season && work.season.show && work.season.show.title
      },
      getSeasonNumber(branch) {
        let work = branch.recording.work
        return work.season && work.season.number
      }
    },
    computed: {
      priority() {
        let p = +this.branch.priority
        return !p ? 0 : (p > 3 ? 4 : p)
      },
      is_committable() {
        return !this.branch.committed_at && !this.branch.branch_stages.find(bs => !bs.committed_at)
      }
    },
    created() {
      api.hub.$on(`branch.${this.branch.id}.branch_stages`, this.repaint)
    },
    beforeDestroy() {
      api.hub.$off(`branch.${this.branch.id}.branch_stages`, this.repaint)
    },
    components: {
      branchActions,
      stage,
      dropdown
    }
  }

</script>

<template src="./index.tpl.html"></template>
<style src="./index.less" lang="less"></style>
