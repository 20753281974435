import 'services/analytics/lib/finnegan'

import AnalyticsHub from 'services/analytics/hub'

/**
 * Analytics function to report events to analytics libraries
 *
 * @constructor
 * @param {String} namespace - Default is 'event', overloaded method
    with more than 2 String arguments will assume the first is the
    namespace
 * @param {String} title - String title of event
 * @param {Object} data - key values of event related properties
 */
export default function() {
  if (typeof arguments[0] === 'string' && typeof arguments[1] === 'string') {
    return AnalyticsHub.$emit(...arguments)
  }
  return AnalyticsHub.$emit('event', ...arguments)
}
