import Vue from 'vue'
import VueRouter from 'vue-router'

import routes from './routes'

import auth from 'services/api/auth'
import Loading from 'services/loading'

Vue.use(VueRouter)

let router = new VueRouter({
  routes,
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  if (to.meta.nocheck) {
    next()
  } else if (to.matched.some(m => m.meta.private)) {
    if (!auth.isAuthenticated()) {
      next('/login')
    } else {
      let is_super_route = to.matched.some(m => m.meta.private && m.meta.private[0] === 'super')
      auth.resolveUser()
        .then(user => {
          if (!is_super_route || user.is_super) {
            Loading.show(to)
            next()
          } else {
            next('/')
          }
        })
        .catch(() => next('/login'))
    }
  } else if (to.matched.some(m => m.meta.public) && auth.isAuthenticated()) {
    Loading.show(to)
    next('/')
  } else {
    Loading.show(to)
    next()
  }
})

router.afterEach((to, from) => {
  Loading.hide()
})

export default router
