import UID from 'services/uid'
import Cookies from 'js-cookie'

/*
  * Browseware
  *
  * Browseware is defined loosely as any platform that
  * is used to access web content
  *
*/

const BROWSER_KEY = 'va_i_bkey'

class Browser {
  _get() {
    return localStorage.getItem(BROWSER_KEY)
  }

  _sync_cookie(id) {
    if (Cookies.get(BROWSER_KEY) !== id) {
      Cookies.set(BROWSER_KEY, id, { domain: 'vidangel.com' })
    }
  }

  get() {
    let id = this._get() || this.set()
    this._sync_cookie(id)
    return id
  }

  set() {
    let id = UID.generate()
    localStorage.setItem(BROWSER_KEY, id)
    return id
  }
}

const browser = new Browser()

export default browser
