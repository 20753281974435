<template>
  <div class="tagcell" :class="klass" >
    <div v-if="showBox" class="box">
      <div v-if="created"><i class="fa fa-bolt"></i></div>
      <div v-else-if="deleted">
        <div class="boxtitle">deleted</div>
        <cell-detail class="detail" :tag="tag"></cell-detail>
      </div>
      <div v-else-if="updated">
        <div class="boxtitle">updated</div>
        <cell-updated class="detail" :tag="tag" :tag-prev="tagPrev"></cell-updated>
      </div>
    </div>
  </div>
</template>

<script>

  import cellDetail from '../cellDetail'
  import cellUpdated from '../cellUpdated'

  export default {
    props: ['branchStage', 'tag', 'tagPrev'],
    data() {
      return {}
    },
    computed: {
      in_stage() {
        return this.tag && ((this.tag.branch_stage && this.tag.branch_stage.id === this.branchStage.id) ||
          (this.tag.master_branch_stage && this.tag.master_branch_stage.id === this.branchStage.id))
      },
      deleted() {
        return this.in_stage && this.tag && this.tag.is_archived
      },
      created() {
        return this.in_stage && this.tag && !this.deleted && !this.tagPrev
      },
      updated() {
        return this.in_stage && !this.deleted && !this.created
      },
      showBox() {
        return this.updated || this.deleted || this.created
      },
      klass() {
        return {
          deleted: this.deleted,
          created: this.created,
          updated: this.updated,
          notag: !this.tag || (!this.deleted && this.tag.is_archived)
        }
      }
    },
    components: {
      cellDetail,
      cellUpdated
    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./index.less" lang="less"></style>
