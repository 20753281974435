import q from 'q'
import api from 'api'
import TagInstance from 'models/TagInstance'

const Tag = api.defineResource({
  name: 'Tag',
  endpoint: 'tagging/tags-virtual',
  watchChanges: false,
  relations: {
    hasMany: {
      // This relation won't work properly unless on JS-DATA v3.
      TagInstance: {
        localKey: 'tag_master_id',
        foreignKey: 'reference_id',
        localField: 'tag_references'
      }
    },
    belongsTo: {
      BranchStage: [
        {
          localField: 'branch_stage',
          localKey: 'branch_stage_id'
        },
        {
          localField: 'master_branch_stage',
          localKey: 'master_branch_stage_id'
        }
      ],
      Category: {
        localField: 'category',
        localKey: 'tag_category_id'
      }
    }
  },
  // cacheResponse: false,
  methods: {
    setCategory(cat) {
      this.tag_category_id = cat.id
      if (cat.title_soft) {
        this.description_short = cat.title_soft
      }
      if (!this.id) {
        this.type = this.category.default_type
        if (this.type === 'audio') {
          this.channel = 'all'
        }
      }
    },
    persist(branch_stage, recording) {
      let id = this.id,
          changes = id ? this.DSChanges().changed : {}
      if (id && !this.DSHasChanges()) {
        return q.resolve({})
      }
      this.catalog_item_id = recording.work_id
      this.recording_id = recording.id
      this.branch_stage_id = branch_stage.id
      this.is_omitted = !!this.is_omitted
      if (this.is_omitted) {
        if (!this.begin) { this.begin = 0 }
        if (!this.end) { this.end = 1 }
      }
      return (id ? this.DSSave() : this.DSCreate())
        .then(tag => [tag, changes])
    },
    getRefId() {
      return this.ref_id || this.id
    },
    isApplied() {
      return this.id && this.tag_instance_id
    },
    isActivable() {
      return this.id && this.tag_instance_id && !this.is_omitted && this.end
    },
    findReferences() {
      return this.tag_master_id ? TagInstance.findAll({
        reference_id: this.tag_master_id
      }) : Promise.resolve([])
    }
  }
})

Tag.handleApiEvent = function(event) {
  let action = event.action,
      tag = event.tag
  if (tag) {
    Tag.eject(tag.id)
    if (tag.ref_id) {
      Tag.eject(tag.ref_id)
      Tag.ejectAll({ ref_id: tag.ref_id })
    }
    if ((action === 'create' || action === 'update') && !tag.is_archived) {
      Tag.inject(tag)
    }
    api.hub.$emit(`branch_stage.${tag.working_id}.tags`)
  }
}

Tag.newify = function(...sets) {
  let props = [
    'branch_stage_id',
    'tag_category_id',
    'description_short',
    'description_full',
    'begin',
    'end',
    'type',
    'is_audio_fc',
    'is_spoiler',
    'is_background',
    'is_plot_critical'
  ], tag = {}
  sets.forEach(attrs => {
    attrs = attrs || {}
    props.forEach(prop => {
      tag[prop] = prop in attrs ? attrs[prop]
      : (prop in tag ? tag[prop] : undefined)
    })
  })
  return Tag.createInstance(tag)
}

export default Tag
