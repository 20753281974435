
import * as d3 from 'd3'
import time from 'services/time'

class FocusXAxis {
  constructor(parent, dimensions, scale) {
    this.dimensions = dimensions

    this.axis = d3.axisBottom(scale)
      .tickFormat(time.seconds.toDisplayShort)
      .tickSize([10])
      .tickPadding([-24])

    this.g = parent.g.append('g')
      .attr('class', 'x axis')
      .attr('transform', `translate(0, 0)`)
      .call(this.axis)

    this.g.selectAll('text')
      .attr('x', 6)

    this.scale = scale
  }

  update() {
    let d = this.scale.domain(),
        r = this.scale.range(),
        dt = d[1] - d[0],
        dr = r[1] - r[0],
        spp = 100 * dt / dr, // seconds per hundred pixels
        i = spp < 2 ? 1
        : spp < 5 ? 5
        : spp < 10 ? 10
        : spp < 25 ? 15
        : spp < 80 ? 60
        : spp < 200 ? 300
        : spp < 800 ? 600
        : 1200
    let ticks = this.generateTickValues(i, d[0], d[1])
    this.axis.tickValues(ticks)
    this.g.call(this.axis)
      .selectAll('text')
      .attr('x', 10)
  }

  redraw() {

  }

  generateTickValues(i, x, x2) {
    let l = x - x % i,
        n = (x2 - l) / i,
        arr = [],
        j = 0

    while (j < n) {
      arr.push(l + (j + 1) * i)
      j++
    }
    return arr
  }
}

export default {
  buildX(...args) {
    return new FocusXAxis(...args)
  }
}
