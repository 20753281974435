import api from 'api'

const Advisory = api.defineResource({
  name: 'Advisory',
  endpoint: 'tagging/advisories',
  cacheResponse: true,
  relations: {},
  methods: {},
  watchChanges: false
})

export default Advisory
