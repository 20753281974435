import * as d3 from 'd3'

import TagManager from 'models/TagManager'

class FocusTags {
  constructor(parent, dimensions, scales) {
    this.scales = scales
    this.parent = parent
    this.dimensions = dimensions
    this.defs = parent.g.append('defs')

    this.g = parent.g.append('g')
      .attr('class', 'focus-tags')
      .attr('transform', `translate(0, ${dimensions.top})`)
  }

  update(tags_data) {
    let x = this.scales.x,
        y = this.scales.y,
        data = tags_data

    this.legend = this.getTagOffsetMapper(data)
    this.mapper = this.legend(y)

    let mapper = this.mapper,
        location = window.location,
        path = location.pathname + location.search + location.hash,
        idGen = t => 'focus-' + t.id,
        pathGen = t => path + '#' + idGen(t),
        classGen = t => `tag tagcat-${t.category.getRootKey()}`

    let defs = this.defs.selectAll('clipPath')
      .data(data, t => t.id)

    // Defs Enter
    defs.enter().append('svg:clipPath')
      .attr('id', idGen)
      .append('svg:rect')
      .attr('width', t => Math.max(x(t.end) - x(t.begin) - 2, 0))
      .attr('height', mapper.getHeight)

    defs.select('rect')
      .attr('width', t => Math.max(x(t.end) - x(t.begin) - 2, 0))
      .attr('height', mapper.getHeight)

    // Defs Exit
    defs.exit().remove()

    let tags = this.g.selectAll('g.tag')
      .data(data, t => t.id)

    // Enter
    let entered = tags.enter().append('svg:g')
      .attr('transform', t => 'translate(' + x(t.begin) + ',' + mapper.getOffset(t) + ')')
      .attr('class', classGen)
      .on('click', this.manageClick.bind(this))

    entered.append('svg:rect')
      .attr('width', t => Math.abs(x(t.end) - x(t.begin)))
      .attr('height', mapper.getHeight)

    entered.append('text')
      .attr('clip-path', t => 'url(' + pathGen(t) + ')')
      .attr('x', 6)
      .attr('y', t => mapper.getHeight(t) / 2)
      .attr('alignment-baseline', 'middle')
      .text(t => t.description_short)

    entered.append('title')
      .text(t => t.description_short)

    // Update
    tags.attr('transform', t => 'translate(' + x(t.begin) + ',' + mapper.getOffset(t) + ')')
      .attr('class', classGen)
      .select('rect')
      .attr('width', t => Math.abs(x(t.end) - x(t.begin)))
      .attr('height', mapper.getHeight)

    tags.exit().remove()
  }

  redraw() {
    if (!this.legend) { return }

    var x = this.scales.x,
        mapper = this.mapper

    // Defs Update
    this.defs.selectAll('rect')
      .attr('width', t => Math.max(x(t.end) - x(t.begin) - 2, 0))
      .attr('height', mapper.getHeight)

    // Tags Update
    this.g.selectAll('g.tag')
      .attr('transform', t => 'translate(' + x(t.begin) + ',' + mapper.getOffset(t) + ')')
      .select('rect')
      .attr('width', t => x(t.end) - x(t.begin))
      .attr('height', mapper.getHeight)
  }

  getTagOffsetMapper(tags) {
    let maxes = {},
        legend = {},
        groups = { audio: [], visual: [] }

    tags.forEach(t => groups[TagManager.getTagType(t)].push(t))

    for (let key in groups) {
      let holder = []
      groups[key]
        .sort((t, t2) => +t.begin - +t2.begin)
        .forEach(t => {
          holder.some((h, i) => {
            if (+h.end <= +t.begin) {
              legend[t.id] = i
              holder[i] = t
              return true
            }
          })
          if (!(t.id in legend)) {
            holder.push(t)
            legend[t.id] = holder.length - 1
          }
        })
      maxes[key] = holder.length
    }

    return scale => {
      let y = 5,
          h = scale.range()[0] - y - 15,
          sets = [
            { key: 'audio', height: 17 },
            { key: 'visual', height: 24 }
          ],
          sum = sets.reduce((sum, d) => (sum + (maxes[d.key] * d.height)), 0),
          heights = {},
          offsets = sets.reduce((set, s) => {
            let range1 = h * maxes[s.key] * s.height / sum,
                domain = [],
                n = maxes[s.key]
            while (n--) { domain[n] = n }
            set[s.key] = d3.scaleBand()
              .range([y, y + range1])
              .domain(domain)
              .paddingInner(0).paddingOuter(0)
            y += range1
            heights[s.key] = s.height
            return set
          }, {})
      return {
        getHeight: t => heights[TagManager.getTagType(t)],
        getOffset: t => {
          let type = TagManager.getTagType(t)
          return offsets[type](legend[t.id])
        }
      }
    }
  }

  manageClick(tag) {
    if (d3.event.metaKey || d3.event.ctrlKey) {
      return this.reportEvent('powerclick', tag)
    }
    if (this.click) {
      // Double Click
      this.reportEvent('doubleclick', tag)
      clearTimeout(this.click)
      this.click = undefined
    } else {
      this.click = setTimeout(() => {
        // Single Click
        this.reportEvent('singleclick', tag)
        this.click = undefined
      }, 250)
    }
  }

  reportEvent(action, data) {
    return this.parent.reportEvent && this.parent.reportEvent(action, data)
  }
}

export default {
  build(...args) {
    return new FocusTags(...args)
  }
}
