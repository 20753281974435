<script>

  export default {
    props: ['season', 'episode'],
    methods: {
      gotToWork() {
        let episode = this.episode,
            id = episode.id
        this.$router.push(`/works/${id}`)
      }
    }
  }

</script>

<template src="./index.tpl.html"></template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./index.less" lang="less"></style>
