<script>

  import Work from 'models/Work'
  import Season from 'models/Season'

  import modal from 'components/modal'
  import episode from 'components/episode'

  export default {
    props: ['show'],
    dataResolve: {
      season() {
        return Season.findOne(this.to.params)
      },
      seasonEpisodes: ['season', season => Work.findAll({ season_id: season.id })]
    },
    methods: {
      episodeCreated() {
        this.creating = false
      },
      episodes() {
        return this.seasonEpisodes.slice()
          .sort((a, b) => a.episode_number - b.episode_number)
      }
    },
    computed: {
      first_air_date() {
        return new Date(this.show.first_air_date)
      }
    },
    components: {
      modal,
      episode
    }
  }

</script>

<template src="./index.tpl.html"></template>
<style src="./index.less" lang="less"></style>
