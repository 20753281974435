import api from 'api'

const Show = api.defineResource({
  name: 'Show',
  endpoint: 'tagging/shows',
  cacheResponse: true,
  relations: {
    hasMany: {
      Season: {
        localField: 'seasons',
        foreignKey: 'show_id'
      }
    }
  },
  methods: {

  },
  watchChanges: false
})

export default Show
