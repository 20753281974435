import UID from 'services/uid'
import Cookies from 'js-cookie'
/*
  * Session
  *
  * Sessions as defined by javascript clients are blocks of
  * continuous user interaction, where continuous is not more
  * then 30 minutes of inactivity.
  *
*/

const session_key = 'va_ts_skey',
      session_active_at_key = 'vs_ts_stskey',
      session_idle_duration = 18e5 // 30 min in milliseconds

class Session {
  constructor() {
    this.touch()
  }

  _get() {
    return localStorage.getItem(session_key)
  }

  _set() {
    let id = UID.generate()
    localStorage.setItem(session_key, id)
    Cookies.set(session_key, id, { domain: 'vidangel.com' })
    return id
  }

  _getActive() {
    return localStorage.getItem(session_active_at_key)
  }

  _setActive() {
    return localStorage.setItem(session_active_at_key, new Date().getTime())
  }

  get() {
    let session_id = this._get(),
        session_last_active = this._getActive() || 0,
        now = new Date().getTime()
    if (session_id && now - session_last_active < session_idle_duration) {
      return session_id
    }
    return this.set()
  }

  set() {
    this._setActive()
    return this._set()
  }

  touch() {
    this._setActive()
  }
}

const session = new Session()

document.addEventListener('mousemove', () => session.touch())
document.addEventListener('keypress', () => session.touch())
document.addEventListener('scroll', () => session.touch(), true)

export default session
