<template>
  <div class="recording-select">
    <h1>Select a Recording</h1>
    <p class="gray">Choose a Recording you know is well tagged. This recording will act as the reference for tag alignment.</p>
    <div class="split">
      <div class="recordings-list">
        <div class="rec-select" v-for="recording in recordings" :key="recording.id" @click="select(recording)" :class="{ active: branch && (recording === branch.recording) }">
          <div class="rec-icon"><img :src="recording.source.app_img_url"></div>
          <div class="rec-title">{{'Recording #' + recording.id}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: ['branch', 'branches', 'branchStage'],
    data() {
      return {
        active: undefined,
        recordings: this.branches
          .map(branch => branch.recording)
          .uniqBy(r => r.id)
          .filter(r => r.branches.find(b => !!b.committed_at))
      }
    },
    methods: {
      select(active) {
        this.active = active
        if (!this.active) { return }
        let branch = this.active.branches
          .filter(branch => branch.committed_at)
          .sort((a, b) => new Date(b.committed_at) - new Date(a.committed_at))[0]
        this.$emit('selected', branch)
      }
    }
  }
</script>
<style lang="less">

  @import '~src/assets/styles/variables.less';

  .recording-select {
    text-align: center;
    max-width: 420px;
    margin: 0 auto;

    .rec-select {
      display: flex;
      height: auto;
      padding: 1em;
      cursor: pointer;
      background-color: @gray-dker;
      .rec-icon {
        flex: 0 0 35px;
        text-align: center;
        img {
          width: auto;
          height: 30px;
        }
      }
      .rec-title {
        flex: 1 1 100%;
        padding-left: 1em;
        font-size: 1.5em;
        display: flex;
        justify-content: flex-start;
        align-content: center;
        align-items: center;
      }
      &:hover {
        background-color: lighten(@gray-dker, 3%);
      }
      &.active {
        background-color: darken(@theme, 3%);
      }
    }
    .split {
      display: flex;
      margin-top: 3em;
      & > * {
        flex: 1 1 50%;
      }
    }
  }
</style>
<!-- <style src="./index.less" lang="less"></style> -->
