let nothis = '"this" is not defined',
    nofx = 'arg must be a function'

// Array Prototype Filter
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/filter#Polyfill
if (!Array.prototype.filter) {
  Array.prototype.filter = function(arg/*, thisArg */) {
    'use strict'
    if (!this) {
      throw new TypeError(nothis)
    }
    if (typeof arg !== 'function') {
      throw new TypeError(nofx)
    }
    var t = Object(this),
        len = t.length >>> 0,
        res = []
    var thisArg = arguments.length >= 2 ? arguments[1] : void 0
    for (var i = 0; i < len; i++) {
      if (i in t) {
        var val = t[i]
        if (arg.call(thisArg, val, i, t)) {
          res.push(val)
        }
      }
    }
    return res
  }
}

// Production steps of ECMA-262, Edition 5, 15.4.4.14
// Reference: https://es5.github.io/#x15.4.4.14
if (!Array.prototype.indexOf) {
  Array.prototype.indexOf = function(search, fromIndex) {
    if (this == null) {
      throw new TypeError(nothis)
    }
    var k,
        o = Object(this),
        len = o.length >>> 0,
        n = fromIndex | 0
    if (len === 0 || n >= len) {
      return -1
    }
    k = Math.max(n >= 0 ? n : len - Math.abs(n), 0)
    while (k < len) {
      if (k in o && o[k] === search) {
        return k
      }
      k++
    }
    return -1
  }
}

// Array Prototype Find
// https://tc39.github.io/ecma262/#sec-array.prototype.find
if (!Array.prototype.find) {
  Array.prototype.indexOf = function(arg) {
    if (!this) {
      throw new TypeError(nothis)
    }
    if (typeof arg !== 'function') {
      throw new TypeError(nofx)
    }
    var o = Object(this),
        len = o.length >>> 0,
        thisArg = arguments[1],
        k = 0
    while (k < len) {
      var kValue = o[k]
      if (arg.call(thisArg, kValue, k, o)) {
        return kValue
      }
      k++
    }
    return undefined
  }
}

// Array Prototype Unique
// NOT ECMA standard, I know :o)
// https://stackoverflow.com/questions/9229645/remove-duplicates-from-javascript-array/9229821#9229821
if (!Array.prototype.uniq) {
  Array.prototype.uniq = function() {
    if (!this) {
      throw new TypeError(nothis)
    }
    var a = []
    this.forEach(function(i) {
      if (a.indexOf(i) === -1) {
        a.push(i)
      }
    })
    return a
  }
}

// Array Prototype UniqueBy
// NOT ECMA standard, I know :o)
// https://stackoverflow.com/questions/9229645/remove-duplicates-from-javascript-array/9229821#9229821
if (!Array.prototype.uniqBy) {
  Array.prototype.uniqBy = function(arg) {
    if (!this) {
      throw new TypeError(nothis)
    }
    if (typeof arg !== 'function') {
      throw new TypeError(nofx)
    }
    var b = []
    return this.filter(function(i) {
      var k = arg(i)
      return b.indexOf(k) >= 0 ? false : b.push(k)
    })
  }
}

// Object assign
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign
if (typeof Object.assign !== 'function') {
  Object.assign = function(target, varArgs) { // .length of function is 2
    'use strict'
    if (target == null) { // TypeError if undefined or null
      throw new TypeError('Cannot convert undefined or null to object')
    }
    var to = Object(target)
    for (var index = 1; index < arguments.length; index++) {
      var nextSource = arguments[index]

      if (nextSource !== null && nextSource !== undefined) { // Skip over if undefined or null
        for (var nextKey in nextSource) {
          // Avoid bugs when hasOwnProperty is shadowed
          if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
            to[nextKey] = nextSource[nextKey]
          }
        }
      }
    }
    return to
  }
}
