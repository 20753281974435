
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

export default {
  show(to) {
    if (to && to.matched) {
      to.matched.find(r => {
        return (r.beforeEnter || r.beforeUpdate) || (r.components &&
          Object.keys(r.components).find(key => {
            let comp = r.components[key]
            return comp.beforeRouteResolve || comp.beforeRouteUpdate || comp.resolve || comp.dataResolve
        }))
      }) && NProgress.start()
    } else {
      NProgress.start()
    }
  },
  hide() {
    NProgress.done()
  }
}
