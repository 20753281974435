<template>

  <div class="navbar" :class="{ compress: $route.meta.is_fullscreen }">

    <div class="content">

      <div class="content-left">
        <router-link class="block logo" to="/" ></router-link>
        <router-link v-if="me" class="block link routeactive-check" :class="{ routeactive: $route.path === '/' }" to="/" >Home</router-link>
        <router-link v-if="me" class="block link" to="/checkouts" >Checkouts</router-link>
        <router-link v-if="me && me.is_super" class="block link" to="/backlog" >Backlog</router-link>
      </div>
      <div class="content-env noselect" v-if="env!=='production'"><span>{{env}}</span></div>
      <div class="content-right">

        <div class="block" v-if="me && me.is_super">
          <!-- <mdbsearch></mdbsearch> -->
          <a class="block link" href="https://api.vidangel.com/aslan/content/catalogitem/" target="_blank">Search</a>
        </div>

        <a class="block link block-menu" @click="is_menu = !is_menu">
          <i class="fa fa-user-circle-o"></i>
        </a>

        <dropdown class="menu-container" :visible="is_menu" animation="ani-slide-y" :position="position" @clickAny="is_menu = false">
          <div class="menu" slot="dropdown" >
            <div class="hline" v-if="me">{{me.email}}</div>
            <router-link class="line" to="/admin" v-if="me && me.is_super">Admin</router-link>
            <router-link class="line" to="/account" >Account</router-link>
            <hr>
            <a class="line" @click="logout">Logout</a>
          </div>
        </dropdown>

        <dropdown class="search-container" :visible="!!search" animation="ani-slide-y" :position="position" @clickOut="search = ''">
          <div class="menu" slot="dropdown" >
            <div>Find movie</div>
          </div>
        </dropdown>

      </div>

    </div>

  </div>

</template>

<script>

  import Auth from 'services/api/auth'

  import dropdown from 'components/dropdown'
  // import mdbsearch from 'components/mdbSearch'
  const env = process.env.VUE_APP_ENV

  export default {
    data() {
      return {
        me: Auth.user,
        is_menu: false,
        search: '',
        position: ['right', 'bottom', 'right', 'top'],
        env
      }
    },
    methods: {
      logout() {
        Auth.logout().then(() => this.$router.push('/login'))
      }
    },
    components: {
      dropdown,
      // mdbsearch
    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./index.less" lang="less"></style>
