<template>
  <div class="checkouts home">
    <h1>
      Checkouts 
      <span v-if="loading" class="loading">
        <img src="/static/img/misc/load_on_black.gif">
      </span>
      <a @click="visibilitylistener()" class="refresh" v-else>
        <i class="fa fa-refresh" />
      </a>
    </h1>
    <div v-if="!loading">
      <div class="branches" v-if="branches.length">
        <branch :branch="branch" v-for="branch in branches" @update="setBranches" :key="branch.id"  />
      </div>
      <div v-else class="gray-lt empty" >Nothing to Checkout</div>
    </div>
  </div>
</template>

<script>

  import api, { socket } from 'api'
  import toast from 'services/toast'

  import Branch from 'models/Branch'
  import BranchStage from 'models/BranchStage'
  import BranchStageAccess from 'models/BranchStageAccess'
  import Stage from 'models/Stage'

  import branch from 'components/branch/detail/checkout'

  let offset = 0,
      page_size = 500, // page size
      limit = 500,
      order_by = '-priority,-created_at',
      orderBy = [
        ['priority', 'DESC'],
        ['created_at', 'DESC']
      ]
  
  let getBranches = () => {
    return Branch.findAll({
      limit,
      order_by,
      filter: {
        committed_at__isnull: true,
        priority__gt: 0
      }
    }, { bypassCache: true })
  }

  export default {
    data() {
      return {
        show: false,
        offset: 0,
        branches: [],
        loading: false,
        visibilitylistener: () => {}
      }
    },
    resolve: {
      branches: getBranches,
      stages: () => Stage.findAll()
    },
    methods: {
      setBranches() {
        this.branches = Branch.filter({
          limit: offset + page_size,
          orderBy,
          where: {
            committed_at: null,
            priority: { '>': 0 }
          }
        })
      }
    },
    components: {
      branch
    },
    created() {
      this.setBranches()
      socket.on('branches', Branch.manage)
      socket.on('branch_stages', BranchStage.manage)
      socket.on('branch_stage_accesses', BranchStageAccess.manage)
      api.hub.$on('branches', this.setBranches)
      this.visibilitylistener = () => {
        this.loading = true
        Branch.ejectAll()
        // BranchStage.ejectAll()
        BranchStageAccess.ejectAll()
        getBranches().then(() => {
          this.setBranches()
          this.branches.forEach(branch => {
            api.hub.$emit(`branch.${branch.id}`, branch)
          })
        }).catch(() => {
          toast("An error occurred. Please refresh to continue.", { theme: 'error' })
        }).then(() =>{
          this.loading = false
        })
      }
      document.addEventListener('visibilitychange', this.visibilitylistener)
    },
    beforeDestroy() {
      socket.off('branches', Branch.manage)
      socket.off('branch_stages', BranchStage.manage)
      socket.off('branch_stage_accesses', BranchStageAccess.manage)
      api.hub.$off('branches', this.setBranches)
      document.removeEventListener('visibilitychange', this.visibilitylistener)
    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./index.less" lang="less"></style>
