<template>
    <div class="field" :class="{ error: err }">
      <label>{{label}}</label>
      <slot></slot>
      <div v-if="err && errorText" class="error-message">{{err}}</div>
    </div>
</template>

<script>

  export default {
    props: ['label', 'path', 'error', 'errorText'],
    computed: {
      err() {
        let data = this.error && this.error.data
        return data && data[this.path] && data[this.path][0]
      }
    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">

  @import '~src/assets/styles/variables.less';

  .field.error {
    input,
    select,
    textarea,
    .input-multipart,
    .input-wrapper {
      border: 1px solid darken(@orange, 5%);
    }
    .error-message {
      color: darken(@orange, 5%);
      margin: 0.5em 0;
    }
  }

</style>
