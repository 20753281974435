<template>
  <div class="manual-align">
    <h1 class="center">Manual Alignment</h1>
    <div class="center">
      <a @click="cancel">Align using scenes</a><br><br>
    </div>
    <field class="formline" path="scale" label="Scale" :error="error">
      <input type="text" name="scale" v-model="scale"/>
    </field>
    <field class="formline" path="translation" label="Translation" :error="error">
      <input type="text" name="translation" v-model="translation" />
    </field>
  </div>
</template>

<script>
  import field from 'components/form/field'
  export default {
    data() {
      return {
        scale: 1.000,
        translation: 0,
        error: undefined
      }
    },
    methods: {
      aligned() {
        this.$emit('aligned', {
          scale: +this.scale || 0,
          translation: +this.translation || 0
        })
      },
      cancel() {
        this.$emit('cancel')
      }
    },
    watch: {
      translation(val) {
        this.aligned()
      },
      scale(val) {
        this.aligned()
      }
    },
    created() {
      this.aligned()
    },
    components: {
      field
    }
  }
</script>
<style type="text/css" lang="less">
  .manual-align {
    margin: 0 auto;
    max-width: 320px;
    .formline {
      max-width: 150px;
      margin-left: auto;
      margin-right: auto;
      input {
        font-size: 1.5em;
      }
    }
  }
</style>
<!-- <style src="./index.less" lang="less"></style> -->
