<script type="text/javascript">
  export default {
    props: ['work'],
    data() {
      return {
        is_overlay: false
      }
    },
    methods: {
      goToWork(work) {
        this.$router.push(`/works/${work.id}`)
      }
    }
  }
</script>
<template src="./index.tpl.html"></template>
<style src="./index.less" lang="less"></style>
