import api from 'api'

const Category = api.defineResource({
  name: 'Category',
  endpoint: 'tagging/tag-categories',
  watchChanges: false,
  relations: {
    hasMany: {
      Category: {
        localField: 'children',
        foreignKey: 'parent_id'
      }
    },
    belongsTo: {
      Category: {
        localField: 'parent',
        localKey: 'parent_id'
      }
    }
  },
  methods: {
    getTree() {
      let cat = this,
          tree = []
      while (cat = cat.parent) {
        tree.unshift(cat)
      }
      return tree
    },
    getRoot() {
      let cat = this
      while (cat.parent) { cat = cat.parent }
      return cat
    },
    getRootKey() {
      return this.root_key || (this.root_key = this.getRoot().key)
    },
    setRootKey() {
      this.root_key = this.getRoot().key
    },
    descendsFrom(parent) {
      let cat = this
      while (cat.parent) {
        if (cat.parent === parent) { return true }
        cat = cat.parent
      }
      return false
    },
    isRoot() {
      return !this.parent_id
    },
    isLeaf() {
      return !Category.getAll().filter(c => c.parent_id === this.id).length
    }
  }
})

Category.query = function(q, scope, fuzzy) {
  let query = `${(fuzzy ? '' : '^')}${q}`,
      regex = new RegExp(query, 'i')
  return Category.getAll()
    .filter(c => regex.test(c.title) && (!scope || c.descendsFrom(scope)))
}

Category.getRoots = function(category) {
  if (!category) {
    return Category.getAll().filter(c => !c.parent_id)
  }
  if (category.children.length) {
    return category.children
  }
  return []
}

export default Category
