<script>

  import modal from 'components/modal'

  export default {
    props: ['tags', 'branch_stage'],
    data() {
      return {
        tags_unapplied: this.tags.filter(t => !t.isApplied()),
        applied_count: 0,
        applying: false,
        done: false,
        errors: []
      }
    },
    methods: {
      applyTags() {
        this.errors = []
        this.applied_count = 0
        this.applying = this.tags_unapplied.reduce((promise, tag) => {
          return promise.then(() => {
            if (!this.applying) { return }
            return this.resolveTagReference(tag).then(ref_tag => {
              tag.begin = ref_tag.begin
              tag.end = ref_tag.end
              tag.type = ref_tag.type
              tag.channel = ref_tag.channel
              return tag.persist(this.branch_stage, this.recording)
            }).catch(error => {
              error = error.data || error
              this.errors.push({ tag, error })
            }).then(() => {
              ++this.applied_count
              if (this.applied_count === this.tags_unapplied.length) {
                this.complete()
              }
            })
          })
        }, Promise.resolve())
      },
      resolveTagReference(tag) {
        return tag.findReferences().then(tag_refs => {
          let ref_tag = tag_refs.find(t => t.end)
          return ref_tag || Promise.reject(Error(`No referencing tags were found.`))
        })
      },
      progress() {
        return Math.ceil((this.applied_count / this.tags_unapplied.length) * 100)
      },
      cancel() {
        this.applying = false
        this.$emit('applied')
        this.$emit('close')
      },
      complete() {
        this.applying = false
        this.$emit('applied')
        if (!this.errors.length) {
          this.$emit('close')
        } else {
          this.done = true
        }
      }
    },
    computed: {
      recording() {
        return this.branch_stage.branch.recording
      },
      show_title() {
        return this.recording.work.getShowTitle()
      },
      title() {
        return this.recording.work.getTitle()
      }
    },
    components: {
      modal
    }
  }
</script>
<template src="./index.html"></template>
<style src="./index.less" lang="less"></style>
