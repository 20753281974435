<script>

  import q from 'q'
  import api from 'api'

  import BranchStage from 'models/BranchStage'
  import BranchStageAccess from 'models/BranchStageAccess'

  import toast from 'services/toast'
  import Auth from 'services/api/auth'
  import actions from 'components/branchStage/actions'

  export default {
    props: ['stage', 'branch', 'branch_stage', 'branch_stage_parent'],
    data() {
      return {
        me: Auth.user,
        assigning: false
      }
    },
    computed: {
      is_committed() {
        return this.branch_stage && this.branch_stage.committed_at
      },
      accesses() {
        return !this.branch_stage ? [] : this.branch_stage.accesses.filter(a => !a.is_revoked && !a.expired_at)
      },
      canAssign() {
        return this.me.is_super || this.me.stage_accesses.find(access => access.stage_id === this.stage.id && access.is_active)
      },
      canAccess() {
        return !!this.accesses.find(access => access.user_id === this.me.id)
      }
    },
    methods: {
      getState() {
        if (this.is_committed) {
          return 'done'
        } else if (!this.stage.parent || (this.getParent() && this.getParent().committed_at)) {
          return 'open'
        } // else undefined
      },
      checkout() {
        return BranchStage.create({
          branch_id: this.branch.id,
          stage_id: this.stage.id
        }).then(resp => {
          this.$emit('branch_stage_created')
          return resp
        })
      },
      assign(user) {
        if (this.assigning) { return }
        let get = this.branch_stage ? q.resolve(this.branch_stage) : this.checkout()
        this.assigning = true
        return get.then(branch_stage => {
          return BranchStageAccess.create({
            branch_stage_id: branch_stage.id,
            user_id: user.id
          })
        }).then(() => {
          this.watchBranchStageStatus()
          this.refresh()
        }).catch(error => {
          toast(error.data.message, { theme: 'error' })
        }).then(() => {
          this.assigning = false
        })
      },
      getBranchStage(stage) {
        return this.branch.branch_stages.find(bs => bs.stage_id === (stage || this.stage).id)
      },
      getAccesses(include_expired) {
        return !this.branch_stage ? [] : this.branch_stage.accesses
          .filter(a => !a.is_revoked && (include_expired || !a.expired_at))
      },
      getParent() {
        if (this.branch_stage) { return this.branch_stage.parent }
        if (this.branch_stage_parent) { return this.branch_stage_parent }
        if (this.stage.parent) { return this.getBranchStage(this.stage.parent) }
      },
      getKey(is_parent) {
        return BranchStage.getSocketKey({
          stage_id: (is_parent ? this.stage.parent : this.stage).id,
          branch_id: this.branch.id
        })
      },
      getValue() {
        let duration = this.branch.recording.duration
        if (!duration) { return '—' }
        return (this.stage.effort_factor * 5 * (duration / 3600)).toFixed(2)
      },
      refresh() {
        this.$forceUpdate()
      },
      watchBranchStageStatus() {
        if (this.branch_stage && this.branch_stage.task) {
          this.timeout = setTimeout(() => {
            this.branch_stage.DSRefresh()
              .catch(() => {})
              .then(() => {
                this.refresh()
                this.watchBranchStageStatus()
              })
          }, 10000)
        }
      },
    },
    components: {
      actions
    },
    created() {
      api.hub.$on(this.getKey(), this.refresh)
      if (this.stage.parent) {
        api.hub.$on(this.getKey(true), this.refresh)
      }
      this.watchBranchStageStatus()
    },
    beforeDestroy() {
      api.hub.$off(this.getKey(), this.refresh)
      if (this.stage.parent) {
        api.hub.$off(this.getKey(true), this.refresh)
      }
    }
  }

</script>

<template src="./index.tpl.html"></template>
<style src="./index.less" lang="less"></style>
