import account from './account'
import session from './session'
import browseware from './browseware'

export default {
  get account() {
    return account.get()
  },
  get session() {
    return session.get()
  },
  get browseware() {
    return browseware.get()
  }
}
