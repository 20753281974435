<script>

  import Show from 'models/Show'
  import Season from 'models/Season'
  import modal from 'components/modal'

  export default {
    dataResolve: {
      show() {
        return Show.find(this.to.params.show_id)
      },
      seasons() {
        return Season.findAll({ show_id: this.to.params.show_id })
      },
      child: ['show', 'seasons', function(show, seasons) {
        // Force Select Season
        if (!this.to.matched.find(r => r.name === 'season')) {
          if (seasons[0]) {
            this.next(`/shows/${show.id}/season/${seasons[0].number}`)
          }
        }
      }]
    },
    components: {
      modal
    }
  }

</script>

<template src="./index.tpl.html"></template>
<style src="./index.less" lang="less"></style>
