import api from 'api'

const Branch = api.defineResource({
  name: 'Branch',
  endpoint: 'tagging/branches',
  relations: {
    hasMany: {
      BranchStage: {
        localField: 'branch_stages',
        foreignKey: 'branch_id'
      }
    },
    belongsTo: {
      Recording: {
        localField: 'recording',
        localKey: 'recording_id'
      },
      Stage: {
        localField: 'ref_stage',
        localKey: 'ref_stage_id'
      },
      Branch: {
        localField: 'parent',
        localKey: 'parent_id'
      },
      User: [
        {
          localField: 'committer',
          localKey: 'committed_by'
        },
        {
          localField: 'creator',
          localKey: 'created_by'
        }
      ]
    },
    hasOne: {
      Branch: {
        localField: 'child',
        foreignKey: 'parent_id'
      }
    }
  },
  methods: {
    commit() {
      return this.DSDetail('commit').put().then(Branch.inject)
    },
    export() {
      return this.DSDetail('export').put()
    },
    getStageSet() {
      if (this.branch_stages && this.branch_stages.length) {
        let branch_stage = this.branch_stages.find(bs => !bs.parent_id),
            stages = [],
            stage
        while (branch_stage) {
          stages.push(stage = branch_stage.stage)
          branch_stage = branch_stage.child
        }
        while (stage = stage.child) {
          stages.push(stage)
        }
        return stages
      }
      return this.ref_stage.getSet()
    },
    getBranchStages() {
      let bs = this.branch_stages.find(bs => !bs.parent_id),
          set = bs ? [bs] : []
      while (bs && bs.child) {
        set.push(bs = bs.child)
      }
      return set
    },
    getStageBranchStage(stage) {
      return this.branch_stages.find(bs => bs.stage_id === stage.id)
    },
    getActiveBranchStage() {
      let bs = this.branch_stages[0]
      while (bs.child) { bs = bs.child }
      return bs
    }
  },
  afterFind(resource, attrs, cb) {
    cb(null, attrs)
    api.hub.$emit(`branch.${attrs.id}.find`)
  },
  afterFindAll(resource, attrs, cb) {
    cb(null, attrs)
    api.hub.$emit('branch.findAll')
  },
  afterLoadRelations(resource, attrs, cb) {
    cb(null, attrs)
    api.hub.$emit('branch.loadRelations')
  },
  afterUpdate(resource, attrs, cb) {
    cb(null, attrs)
    api.hub.$emit(`branch.${attrs.id}`)
  },
  afterCreate(resource, attrs, cb) {
    cb(null, attrs)
    api.hub.$emit(`branch.${attrs.id}`)
  },
  afterEject(resource, attrs) {
    attrs = Array.isArray(attrs) ? attrs : [attrs]
    attrs.forEach(branch => {
      api.hub.$emit(`recording.${branch.recording_id}.branches`)
    })
    api.hub.$emit(`branches`)
  },
  watchChanges: false
})

Branch.safeInject = branch => {
  Branch.inject(branch)
  api.hub.$emit(`branches`)
  api.hub.$emit(`branch.${branch.id}`)
  api.hub.$emit(`branch.${branch.id}.branch_stages`)
  api.hub.$emit(`recording.${branch.recording_id}.branches`)
}

export default Branch
