<script>

  import Category from 'models/TagCategory'

  export default {
    props: ['autofocus'],
    data() {
      return {
        current: 0,
        items: [],
        query: ''
      }
    },
    methods: {
      up() {
        if (this.current > 0) {
          this.current--
        } else {
          this.current = this.items.length - 1
        }
      },
      down(event) {
        if (this.current < this.items.length - 1) {
          this.current++
        } else {
          this.current = 0
        }
      },
      update() {
        this.setItems()
      },
      select(item) {
        let target = this.temp = item || this.items[this.current]
        if (target.isLeaf()) {
          // broadcast done!
          this.$emit('selected', target)
          this.close()
        } else {
          this.query = ''
          this.setItems()
        }
      },
      escape() {
        if (this.temp) {
          this.temp = undefined
          this.setItems()
        } else {
          this.close()
        }
      },
      close() {
        this.query = ''
        this.items = []
        this.temp = undefined
        this.$emit('cancel')
      },
      setActive(index) {
        this.current = index
      },
      activeClass(index) {
        return {
          active: this.current === index
        }
      },
      setItems() {
        let items,
            q = this.query,
            cat = this.temp
        if (!this.query) {
          items = this.temp ? this.temp.children : Category.getRoots()
        } else {
          items = Category.query(q, cat)
          if (items.length < 8) {
            items = items.concat(Category.query(q, cat, true)).uniq()
          }
        }
        this.items = items.slice(0, 7)
        this.current = 0
      }
    },
    mounted() {
      if (this.autofocus) {
        document.getElementById('categorypickerinput').focus()
      }
    }
  }
</script>
<template src="./index.tpl.html"></template>
<style src="./index.less" lang="less"></style>
