import http from 'services/http'
import Identity from 'services/identity'
import Hub from 'services/analytics/hub'
import Utils from 'services/analytics/utils'
import BrowserReport from 'services/browser_report'

const { VUE_APP_ANALYTICS_URL, VUE_APP_BUILD_VERSION } = process.env

function report(namespace, table, data) {
  data = [data, {
    rid: Identity.browseware,
    iid: Identity.session,
    aid: Identity.account || data.aid, // Allow aid set for signup event
    // pid: , Not yet supported
    // lid: , Not yet supported,
    platform: 'web',
    os_name: BrowserReport.os.name,
    os_version: BrowserReport.os.version,
    browser_name: BrowserReport.browser.name,
    browser_version: BrowserReport.browser.version,
    hostname: window.location && window.location.hostname,
    build_version: VUE_APP_BUILD_VERSION
  }].reduce((set, obj) => Object.assign(set, obj), {})
  return http.post(`${VUE_APP_ANALYTICS_URL}${namespace}/${table}`, data)
}

Hub.$on('ab_test_exposure', (test, data = {}) => {
  report('user_test', `${test}_exposures`, data)
})

Hub.$on('event', (event, data = {}, datasets = {}) => {
  data = Object.assign({}, data)
  if (datasets.route) {
    Object.assign(data, Utils.getRouteParams(datasets.route))
  }
  if (datasets.work) {
    Object.assign(data, Utils.getWorkInfo(datasets.work))
  }
  if (event === 'page_viewed') {
    Object.assign(data, Utils.getUTMParams())
  }
  report('event', event, data)
})
