<template>
  <div>
    <navbar></navbar>
    <div class="main-wrap" :class="{ fullscreen: $route.meta.is_fullscreen }">
      <div class="main">
        <!-- Content will be placed here -->
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>

  import navbar from 'components/navbar'

  export default {
    components: {
      navbar
    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./index.less" lang="less"></style>
