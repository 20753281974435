import * as d3 from 'd3'

class CursorGhost {
  constructor(parent, dimensions, scale) {
    let height = 20,
        width = dimensions.width

    this.g = parent.g.append('g')
      .attr('class', 'cursor-ghost')
      .attr('transform', 'translate(0,0)')

    this.gGhost = this.g.append('g')
      .attr('class', 'cursor')
      .attr('transform', 'translate(0,0)')

    this.gGhost.append('line')
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', 0)
      .attr('y2', 0)

    this.gGhost.append('circle')
      .attr('r', '0')
      .attr('cx', '0')
      .attr('cy', '0')

    this.backdrop = this.g.append('rect')
      .attr('x', 0)
      .attr('y', -height / 2)
      .attr('width', width)
      .attr('height', height)
      .style('fill', 'transparent')
      .on('mouseenter', () => {
        this.gGhost.select('circle').attr('r', '6')
        this.gGhost.select('line').attr('y2', dimensions.height)
        this.set()
      })
      .on('mousemove', () => this.set())
      .on('click', () => this.hide())
      .on('mouseout', () => this.hide())
  }

  set() {
    this.gGhost.attr('transform', `translate(${d3.event.offsetX - 3},0)`)
  }

  hide() {
    this.gGhost.select('circle').attr('r', '0')
    this.gGhost.select('line').attr('y2', '0')
  }
}

export default {
  build(...args) {
    return new CursorGhost(...args)
  }
}
