import asyncLoader from 'services/asyncLoad'

const storage_key = 'hls_player_version_key'
const hls_settings = {
  versions: [
    {
      key: '1105`',
      title: 'HLS 1.1.5 (default)',
      url: 'https://cdn.jsdelivr.net/npm/hls.js@1.1.5'
    },
    {
      key: '1412`',
      title: 'HLS 1.4.12',
      url: 'https://cdn.jsdelivr.net/npm/hls.js@1.4.12'
    },
  ],
  setVersion(version) {
    localStorage.setItem(storage_key, version.key)
    return asyncLoader.loadScript(`${version.url}?v=${new Date().getTime()}`)
  },
  getVersion() {
    let key = localStorage.getItem(storage_key)
    return hls_settings.versions.find(v => v.key === key) || hls_settings.versions[0]
  }
}

export default hls_settings
