
<template>
  <div class="deltas">
    <span class="bs-delta bs-delta-created">+{{ branchStage.tags_created_computed || 0 }}</span>
    <span class="bs-delta bs-delta-updated">~{{ branchStage.tags_updated_computed || 0 }}</span>
    <span class="bs-delta bs-delta-deleted">-{{ branchStage.tags_deleted_computed || 0 }}</span>
  </div>
</template>

<script>
  export default {
    props: ['branchStage']
  }
</script>

<style lang="less">
  @import '~src/assets/styles/variables.less';

  .deltas {
    display: inline-block;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.15em;
    width: 10em;
    .bs-delta {
      // font-size: 1.1em;
      // font-weight: 500;
      margin-right: 2em;
      &-created {
        color: lighten(@green, 10%);
      }
      &-updated {
        color: lighten(@yellow, 10%);
      }
      &-deleted {
        color: lighten(@red, 10%);
      }
    }
  }
</style>
