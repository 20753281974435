<template>
  <layout>
    <router-view :key="$route.fullPath"></router-view>
  </layout>
</template>

<script>

  import auth from 'services/api/auth'

  import layout from 'components/layouts/default'

  export default {
    resolve: {
      user: () => auth.resolveUser()
    },
    components: {
      layout
    }
  }

</script>
