import api from 'services/api'
import User from 'models/User'
import Cookies from 'js-cookie'

import AuthServices from 'services/api/auth/services'

class Auth {
  /**
   * Initialize the current user if there is already an auth token
   */
  constructor() {
    let get = Cookies.get,
        token2 = get('authTokenImpersonate'),
        token = token2 || get('authToken'),
        personated = get('impersonated')

    // Check if currently or recently impersonating
    if (token2 && token2 !== personated) {
      this.impersonating = token2
      Cookies.set('impersonated', token2, { expires: 1000 })
    } else if (personated) {
      Cookies.remove('impersonated')
      token = undefined
    }
    this.request = token
      ? this._setAuth(token, { resuming_session: true })
      : this._clearAuth('No Token').catch(() => {})
  }

  /*
   * Sends a request to login the user. If the request was successful the
   * auth token is saved to cookies and the Authorization
   * header is set. If the request failed the auth token and Authorization
   * header will be deleted.
   *
   * @param {string} username Username of the user.
   * @param {string} password Password of the user.
   *
   * @returns {promise}
  */
  login(username, password) {
    return api.POST('api-token-auth', { username, password }, {}, true)
      .then(res => this._setAuth(res.data.token, res.data.id))
      .catch(err => this._clearAuth(err))
  }

  /**
   * Logs out the user by removing the auth token from the app.
   *
   * @param {boolean} no_redirect
   *
   */
  logout(no_redirect) {
    let opts = { aid: this.user.id }
    return this._clearAuth('Logout')
      .then(() => AuthServices.onLogout(no_redirect, opts))
      .catch(() => {})
  }

  /**
   * Sets the authToken in the session storage and the Authorization header.
   *
   * @param {string} authToken The auth token to store.
   *
   * @private
   */
  _setAuth(token, opts={}) {
    let domain = process.env.VUE_APP_ENV === 'development' ? undefined : 'vidangel.com'
    Cookies.set('authToken', token, { expires: 1000, domain })
    api.setHeader('Authorization', `Token ${token}`)
    this.request = User.find('current')
      .then(user => {
        this.user = user
        return AuthServices.onLogin(user, opts)
      })
    return this.request
  }

  /**
   * Removes the auth token from cookie storage, removes user from
   * session storage.
   *
   * @param {string} message The reason for clearing auth.
   *
   * @private
   */
  _clearAuth(err) {
    // ToDo Remove UserId after July 2021
    ['userId', 'impersonated', 'authToken'].forEach(Cookies.remove)
    Cookies.remove('authToken', { domain: 'vidangel.com' })
    Cookies.remove('profileId', { domain: 'vidangel.com' })
    this.user = undefined
    this.profile = undefined
    this.impersonating = undefined
    api.deleteHeader('Authorization')
    api.deleteHeader('Profile')
    let message = err
    if (Object(err) === err) {
      message = err.message || 'Invalid username or password.'
    }
    this.request = Promise.reject(Error(message))
    return this.request
  }

  /**
   * Returns true if the current session has an auth token stored. Returns
   * false otherwise.
   *
   * @returns {boolean}
   */
  isAuthenticated() {
    return !!Cookies.get('authToken')
  }

  resolveUser() {
    return (this.isAuthenticated() ? this.request.then(r => r.user) : Promise.reject()).catch(() => {
      window.location = process.env.VUE_APP_LOGIN_URL
    })
  }
}

export default new Auth()
