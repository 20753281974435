
const zero = (d, z) => (Array(z).join('0') + d).slice(-z)

const time = {
  zero,
  seconds: {
    toM(s, z) {
      let d = Math.floor(s / 60)
      return z ? zero(d, 3) : d
    },
    toS(s, z) {
      let d = Math.floor(s % 60)
      return z ? zero(d, 2) : d
    },
    toMs(s, z) {
      let d = Math.floor((s % 1) * 1000)
      return z ? zero(d, 3) : d
    },
    toDisplay: (sc, abbr) => {
      let t = time.seconds,
          m = t.toM(sc),
          s = t.toS(sc, true),
          l = t.toMs(sc, true)
      return `${m}:${s}` + (abbr ? '' : `.${l}`)
    },
    toDisplayShort: sc => {
      let t = time.seconds,
          m = t.toM(sc),
          s = t.toS(sc, true)
      return `${m}:${s}`
    },
    toDisplayMin: sc => {
      let t = time.seconds,
          m = t.toM(sc)
      return `${m}`
    }
  },
  ago(date) {
    let s = (new Date() - new Date(date)) / 1000,
        m = 60,
        h = m * 60,
        d = h * 24,
        w = d * 7,
        M = w * 30,
        unit
    if (s < m) {
      return 'just now'
    } else if (s < h) {
      s = s / m
      unit = 'minute'
    } else if (s < d) {
      s = s / h
      unit = 'hour'
    } else if (s < w) {
      s = s / w
      unit = 'day'
    } else if (s < M) {
      s = s / w
      unit = 'week'
    } else {
      s = s / M
      unit = 'month'
    }
    s = Math.round(s)
    return `${s} ${unit}${s < 2 ? '' : 's'} ago`
  }
}

export default time
