<template>
  <div >
    <router-view :key="$route.fullPath"></router-view>
    <vue-toast ref='toast'></vue-toast>
  </div>
</template>

<script>

  import router from 'router'

  import VueToast from 'vue-toast'

  import toast from 'services/toast' // eslint-disable-line no-unused-vars

  /* eslint-disable no-new */
  export default {

    /* Router */
    router,

    components: {
      VueToast
    },

    mounted() {
      toast.init(this.$refs.toast)
    }

  }

</script>
<style type="text/css" lang="less">
  @import '~assets/styles/app.less';
</style>
