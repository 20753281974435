
<script >

  import Work from 'models/Work'
  import Season from 'models/Season'

  export default {
    resolve: {
      redirect() {
        let p = this.to.params
        return Season.findOne({ season__show__catalog_item_id: p.show_id, number: p.season_number })
          .then(season => {
            if (!season) {
              throw Error(`Season ${p.season_number} could not be found`)
            }
            return Work.findOne({ season_id: season.id, episode_number: p.episode_number })
              .then(work => this.next(`/works/${work.id}`))
          })
      }
    }
  }

</script>
