
import CursorGhost from './ghost/ghost'

class FocusCursor {
  constructor(parent, dimensions, scale) {
    let height = dimensions.height
    // hwidth = 15
    this.scale = scale

    this.ghost = CursorGhost.build(parent, dimensions, scale)

    this.g = parent.g.append('g')
      .attr('class', 'cursor')
      .attr('transform', 'translate(0,0)')
    this.g.append('line')
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', 0)
      .attr('y2', height)
    this.g.append('circle')
      .attr('r', '6')
      .attr('cx', '0')
      .attr('cy', '0')
  }

  redraw(time) {
    let x = this.scale.x.clamp(false)(time)
    this.scale.x.clamp(true)
    this.g.attr('transform', `translate(${x},0)`)
  }
}

export default {
  build(...args) {
    return new FocusCursor(...args)
  }
}
