<template>
  <div class="account">
    <h2>Please reset your password or make other changes to your account by visiting <a href="https://www.vidangel.com">www.vidangel.com</a></h2>
  </div>
</template>

<script>

  export default {
    data() {
      return {}
    },
    methods: {

    },
    components: {

    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
