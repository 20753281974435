<template src="./index.tpl.html"></template>
<script>
  import api from 'api'

  import User from 'models/User'
  import Branch from 'models/Branch'
  import Stage from 'models/Stage'
  import Playlist from 'models/Playlist'

  import toast from 'services/toast'
  import video from 'services/video'
  import branch from 'components/branch'
  import stageSelect from 'components/stage/select'

  export default {
    props: ['recording'],
    data() {
      return {
        working: false,
        loading: false,
        selecting: false,
        show_error: false,
        show: false,
        branches: [],
        user: ''
      }
    },
    methods: {
      workon() {
        if (this.working) {
          return this.working = false
        }
        this.working = this.loading = true
        Branch.findAll({
          filter: {
            recording_id: this.recording.id
          }
        }).then(branches => {
          this.branches = this.recording.branches
          this.loading = false
        })
      },
      setBranches() {
        this.branches = Branch.filter({
          where: {
            recording_id: this.recording.id
          }
        })
      },
      setMeta() {
        return video.getDuration(
          Playlist.get({ 
            recording_id: this.recording.id
          })
        ).then(duration => this.recording.DSUpdate({ duration }))
      },
      createBranch(stage) {
        Branch.create({
          recording_id: this.recording.id,
          ref_stage_id: stage ? stage.id : Stage.getAll().find(s => s.slug === 'tag').id
        }).then(() => {
          this.branches = this.recording.branches
          this.selecting = false
        }).catch(error => {
          toast(error.data.message, { theme: 'error' })
        })
      },
      render() {
        this.$forceUpdate()
      },
    },
    components: {
      stageSelect,
      branch
    },
    computed: {
      reviseable() {
        return !this.branches.find(b => !b.committed_at)
      },
      branchesSorted() {
        return this.branches.slice().sort((a,b) => a.created_at < b.created_at ? -1 : 1)
      }
    },
    beforeMount() {
      if (this.recording.account_id) {
        User.find(this.recording.account_id).then(u => this.user = u)
      }
    },
    created() {
      let id = this.recording.id
      api.hub.$on(`recording.${id}`, this.render)
      api.hub.$on(`recording.${id}.branches`, this.setBranches)
    },
    beforeDestroy() {
      let id = this.recording.id
      api.hub.$off(`recording.${id}`, this.render)
      api.hub.$off(`recording.${id}.branches`, this.setBranches)
      clearTimeout(this.timeout)
    }

  }

</script>
<style src="./index.less" lang="less"></style>
