import q from 'q'
import api from 'api'

const StageAccess = api.defineResource({
  name: 'StageAccess',
  endpoint: 'tagging/stage-accesses',
  relations: {},
  methods: {},
  watchChanges: false
})

StageAccess.getUserMap = function(user, stages) {
  let index = {}
  user.stage_accesses.forEach(sa => index[sa.stage_id] = sa.is_active)
  return stages.reduce((map, stage) => {
    map[stage.id] = index[stage.id]
    return map
  }, {})
}

StageAccess.resolveUserAccessMap = function(user, map_i, map) {
  let actions = []
  for (let id in map) {
    let old = map_i[id],
        now = map[id]
    // Didn't exist, now true
    if (old === undefined && now) {
      actions.push(StageAccess.create({
        stage_id: id,
        user_id: user.id,
        is_active: !!now
      }))
    // Was false, now true, or was true, now false
    } else if (old !== undefined && (!now !== !old)) {
      let access = StageAccess.filter({
        where: { user_id: user.id, stage_id: id }
      })[0]
      actions.push(access.DSUpdate({
        is_active: !!now
      }))
    }
  }
  return q.all(actions)
}

export default StageAccess
