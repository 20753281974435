import * as d3 from 'd3'

import ContextCursor from './cursor/cursor'
// import ContextTags from './tags/tags';

class Context {
  constructor(parent, dimensions, brush) {
    this.dimensions = dimensions
    this.margin = { top: 0, right: 0, bottom: 0, left: 0 }
    this.g = parent.g.append('g')
      .attr('class', 'context')
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')')
    this.scale = {
      x: d3.scaleLinear().range([0, this.getWidth()])
        .clamp(true)
    }
    this.brush = brush
    this.gBrush = this.g.append('g')
      .attr('class', 'brush')
      .call(brush)

    this.cursor = ContextCursor.build(this, dimensions, this.scale)
  }

  getWidth() {
    return this.dimensions.width - this.margin.left - this.margin.right
  }

  getHeight() {
    return this.dimensions.height - this.margin.top - this.margin.bottom
  }

  moveBrush(range) {
    return this.gBrush.call(this.brush.move, range)
  }

  setDomain(domain) {
    this.scale.x.domain(domain)
  }

  redrawCursor(time) {
    this.cursor.redraw(time)
  }
}

export default {
  build(parent, dimensions, brush) {
    return new Context(parent, dimensions, brush)
  }
}
