import q from 'q'
import api from 'api'

const WorkAdvisory = api.defineResource({
  name: 'WorkAdvisory',
  endpoint: 'tagging/work-advisories',
  watchChanges: false,
  relations: {},
  methods: {}
})

WorkAdvisory.resolveMap = function(work, map) {
  let actions = [],
      map_i = work.getAdvisoryMap()
  for (let id in map) {
    let old = map_i[id],
        now = map[id]
    // Didn't exist, now true
    if (old === undefined && now) {
      actions.push(WorkAdvisory.create({
        advisory_id: id,
        catalog_item_id: work.id,
        is_active: true
      }))
    // Was false, now true, or was true, now false
    } else if (old !== undefined && (!now !== !old)) {
      let adv = work.advisories.find(adv => adv.advisory_id === +id)
      adv.is_active = !!now
      actions.push(adv.DSSave())
    }
  }
  return q.all(actions)
}

export default WorkAdvisory
