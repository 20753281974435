
import fecha from 'fecha'
import numeral from 'numeral'
import time from './time'

const filters = {
  // https://github.com/taylorhakes/fecha
  date(date, format) {
    return fecha.format(new Date(date), format || 'MMM D, YYYY h:MM a')
  },
  // http://numeraljs.com/
  numrl(number, format) {
    return numeral(number).format(format)
  },
  ago(date) {
    return time.ago(date)
  },
  secondsDisplay: time.seconds.toDisplay,
  secondsDisplayMin: time.seconds.toDisplayMin,
  secondsDisplayShort: time.seconds.toDisplayShort
}

export default Vue => {
  for (let key in filters) {
    Vue.filter(key, filters[key])
  }
}
