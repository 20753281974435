import q from 'q'

function isFx(f) {
  return typeof f === 'function'
}

function isArr(a) {
  return Object.prototype.toString.call(a) === '[object Array]'
}

export default function(tree) {
  let keys = [],
      promises = [],
      pending = {},
      self = this

  function register(key, func) {
    let row = pending[key] = pending[key] || []
    row.push(func)
  }

  function report(key, value) {
    (pending[key] || []).forEach(f => f.call(self, value))
    return value
  }

  for (let key in tree) {
    if (!tree.hasOwnProperty(key)) { return }
    let branch = tree[key],
        promise
    if (isArr(branch)) {
      promise = q.Promise((resolve, reject) => {
        let props = branch.slice(0, -1),
            callback = branch.slice(-1)[0],
            missing = props.find(p => !(p in tree))

        if (missing) {
          return reject(`Property '${missing}' not in tree!`)
        }

        if (!isFx(callback)) {
          return reject('Branch must end with callback')
        }

        if (!props.length) {
          return run()
        }

        let args = [],
            count = 0

        props.forEach((k, i) => {
          register(k, value => {
            args[i] = value
            if (++count === props.length) {
              run()
            }
          })
        })

        function run() {
          try {
            q.resolve(callback.apply(self, args))
              .then(resolve)
              .catch(reject)
          } catch (err) {
            reject(err)
          }
        }
      })
    } else {
      promise = q.resolve(isFx(branch) ? branch.call(self) : branch)
    }
    promise.then(val => report(key, val))
    keys.push(key)
    promises.push(promise)
  }

  return q.all(promises).then(results => {
    return keys.reduce((result, key, index) => {
      result[key] = results[index]
      return result
    }, {})
  })
};
