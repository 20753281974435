import q from 'q'

function loader(createElement) {
  var promises = {}
  return function(url) {
    if (typeof promises[url] === 'undefined') {
      var deferred = q.defer(),
          el = createElement(url)
      el.onload = el.onreadystatechange = r => {
        if (el.readyState && el.readyState !== 'complete' && el.readyState !== 'loaded') {
          return
        }
        deferred.resolve(r)
      }
      el.onerror = e => deferred.reject(e)
      promises[url] = deferred.promise
    }
    return promises[url]
  }
}

export default {
  loadScript: loader(src => {
    var script = document.createElement('script')
    script.src = src
    document.body.appendChild(script)
    return script
  }),
  loadStyles: loader(href => {
    var style = document.createElement('link')
    style.rel = 'stylesheet'
    style.type = 'text/css'
    style.href = href
    document.head.appendChild(style)
    return style
  }),
  get(url) {
    let defer = q.defer(),
        image = new Image()
    image.src = url
    if (image.complete) {
      defer.resolve()
    } else {
      image.addEventListener('load', defer.resolve)
      image.addEventListener('error', defer.reject)
    }
    return defer.promise
  }
}
