import api from 'api'

const TagInstance = api.defineResource({
  name: 'TagInstance',
  endpoint: 'tagging/tag-instances',
  watchChanges: false,
  relations: {
    belongsTo: {
      Recording: [
        {
          localField: 'recording',
          localKey: 'recording_id'
        }
      ]
    }
  }
})

export default TagInstance
