<script>

  import api, { socket } from 'api'
  import video from 'services/video'
  import asyncLoader from 'services/asyncLoad'
  import Auth from 'services/api/auth'
  import hls_settings from 'services/hls_settings'

  import Tag from 'models/Tag'
  import Work from 'models/Work'
  import Stage from 'models/Stage'
  import Recording from 'models/Recording'
  import Advisory from 'models/Advisory'
  import Branch from 'models/Branch'
  import BranchStage from 'models/BranchStage'
  import BranchStageAccess from 'models/BranchStageAccess'
  import Category from 'models/TagCategory'
  import Playlist from 'models/Playlist'

  import workAdvisories from 'components/work/advisories'
  import modal from 'components/modal'
  import player from 'components/player'
  import timeline from './timeline'
  import tags from './tags'
  import stageControls from './stageControls'
  import Session from './session'
  import vueSlider from 'vue-slider-component'

  let cache = 0

  export default {
    data() {
      return {
        time: 0,
        speed: 1,
        speeds: [1 / 4, 1 / 2, 0.66, 3 / 4, 1],
        goodbye: false,
        modal_commit: false,
        modal_unassign: false,
        tagmap: {},
        // playeron: true,
        user: Auth.user,
        hls_version: hls_settings.getVersion(),
        hls_versions: hls_settings.versions,
        hls_version_changing: false
      }
    },
    dataResolve: {
      branch() {
        return Branch.find(this.to.params.branch_id)
      },
      stage() {
        return Stage.findAll().then(stages => {
          let stage = stages.find(s => s.slug === this.to.params.stage_key)
          if (!stage) {
            throw Error('No Stage')
          }
          return stage
        })
      },
      branch_stage: ['branch', 'stage', function(branch, stage) {
        return BranchStage.findAll({ 
          filter: { 
            branch_id: branch.id 
          } 
        }, { bypassCache: true })
          .then(branch_stages => {
            let branch_stage = branch_stages.find(b => b.stage_id === stage.id)
            if (!branch_stage) {
              throw Error('No Branch Stage')
            }
            return branch_stage
          })
      }],
      branch_stage_access: ['branch_stage', (branch_stage) => {
        let user = Auth.user
        return BranchStageAccess.findAll({
          user_id: user.id,
          branch_stage_id: branch_stage.id
        }).then(bsas => {
          let bsa = bsas.find(bsa => !bsa.is_revoked && !bsa.expired_at)
          if (!bsa && !user.is_super) {
            throw Error('Sorry! It doesn\'t look like you have access to this stage')
          }
          return bsa
        })
      }],
      recording: ['branch', branch => {
        return Recording.find(branch.recording_id).then(recording => {
          return recording.duration ? recording : video.getDuration(
            Playlist.get({ 
              recording_id: recording.id,
              c: cache++
            })
          ).then(duration => rec.DSUpdate({ duration }))
        })
      }],
      work: ['recording', recording => Work.find(recording.work_id)],
      tags: ['branch_stage', 'recording', (branch_stage, recording) => {
        return Tag.findAll({
          branch_stage_id: branch_stage.id,
          recording_id: recording.id,
          is_archived: false
        }, { bypassCache: true })
      }],
      source: ['branch_stage', 'recording', (branch_stage, recording) => {
        return Playlist.get({
          recording_id: recording.id,
          branch_stage_id: branch_stage.id,
          c: cache++
        }, {})
      }],
      advisories: () => Advisory.findAll()
    },
    resolve: {
      player: () => asyncLoader.loadScript(hls_settings.getVersion().url),
      category: () => Category.findAll()
    },
    methods: {
      mouseclick() {
        this.last_user_interaction = new Date()
      },
      keydown(event) {
        this.last_user_interaction = new Date()
        if (['INPUT', 'TEXTAREA', 'SELECT'].indexOf(event.target.nodeName) > -1) {
          return
        }
        if (this.$refs.player) {
          if (event.keyCode === 75) {
            this.$refs.player.fullscreen(false)
            return
          }
          this.$refs.player.keyEvent(event)
        }
      },
      setSpeed(speed) {
        this.speed = speed || 1
      },
      setTime(time) {
        this.time = time || 0
      },
      toTime(time) {
        this.$refs.player.time(time)
      },
      setBranchStage() {
        this.branch_stage = BranchStage.get(this.branch_stage.id)
      },
      setTags() {
        this.tags = this.branch_stage.tags.filter(tag => !tag.is_archived)
      },
      editTag(tag) {
        this.$refs.tags.editTag(tag)
      },
      viewTag(tag) {
        let start = Math.max(tag.begin - 2, 0)
        // make sure tag is on
        if (!this.tagmap[tag.id]) {
          this.tagmap[tag.id] = true
          this.setSource({ start, autoplay: true })
        } else {
          this.toTime(start)
          this.$refs.player.play()
        }
      },
      setSource(opts) {
        this.source = Playlist.get({
          recording_id: this.recording.id,
          branch_stage_id: this.branch_stage.id,
          c: cache++
        }, this.tagmap)
        return this.$refs.player.load(this.source, opts)
      },
      commit() {
        this.report(true)
        this.branch_stage.commit()
          .then(() => this.closeCommitModal())
          .then(() => this.goodbye = true)
      },
      unassign() {
        this.branch_stage_access.unassign().then(() => {
          this.closeUnassignModal()
          this.branch_stage_access = undefined
        })
      },
      closeCommitModal() {
        this.modal_commit = false
      },
      closeUnassignModal() {
        this.modal_unassign = false
      },
      hlsChange(version) {
        if (document.activeElement !== document.body) {
          document.activeElement.blur()
        }
        this.hls_version_changing = true
        return hls_settings.setVersion(version)
          .then(() => this.hls_version_changing = false)
      },
      render() {
        this.$forceUpdate()
      }
    },
    computed: {
      canWorkRevision() {
        return !this.branch_stage_access && !this.user.is_super
      }
    },
    components: {
      tags,
      modal,
      player,
      // aligner,
      timeline,
      vueSlider,
      stageControls,
      workAdvisories
    },
    created() {
      this.session_id = URL.createObjectURL(new Blob([])).slice(-36)
      this.last_user_interaction = new Date()
      this.report = force => {
        const video = this.$refs.player.$refs.video
        Session.report({
          access_id: this.branch_stage_access?.id,
          session_id: this.session_id,
          last_user_interaction: this.last_user_interaction,
          is_playing: video && !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2),
          is_tab_focused: document.hasFocus(),
        }, force)
      }
      this.studioAnalytics = setInterval(() => {
        this.report()
      }, Session.REPORT_FREQUENCY_MS)
      document.addEventListener('click', this.mouseclick)
      document.addEventListener('keydown', this.keydown)

      let id = this.branch_stage.id,
          bid = this.branch.id,
          key = BranchStage.getSocketKey(this.branch_stage)

      socket.on(`branches`, Branch.manage)
      socket.on(`branch_stages`, BranchStage.manage)
      socket.on(`branch_stage.${id}.tags`, Tag.handleApiEvent)

      api.hub.$on(key, this.setBranchStage)
      api.hub.$on(`branch.${bid}`, this.render)
      api.hub.$on(`branch_stage.${id}.tags`, this.setTags)
    },
    beforeDestroy() {
      this.report(true)
      clearInterval(this.studioAnalytics)
      document.removeEventListener('click', this.mouseclick)
      document.removeEventListener('keydown', this.keydown)
      let id = this.branch_stage.id,
          bid = this.branch.id,
          key = BranchStage.getSocketKey(this.branch_stage)

      socket.off(`branches`, Branch.manage)
      socket.off(`branch_stages`, BranchStage.manage)
      socket.off(`branch_stage.${id}.tags`, Tag.handleApiEvent)

      api.hub.$off(key, this.setBranchStage)
      api.hub.$off(`branch.${bid}`, this.render)
      api.hub.$off(`branch_stage.${id}.tags`, this.setTags)
    }

  }

</script>
<template src="./index.html"></template>
<style src="./index.less" lang="less"></style>
