<script>

  import api, { socket } from 'api'

  import Branch from 'models/Branch'
  import BranchStage from 'models/BranchStage'
  import BranchStageAccess from 'models/BranchStageAccess'
  import Stage from 'models/Stage'

  import branch from 'components/branch/detail/checkout'

  let limit = 20,
      page_size = 20, // page size
      orderBy = [
        ['updated_at', 'DESC']
      ]

  export default {
    data() {
      return {
        show: false,
        offset: 0,
        branches: []
      }
    },
    resolve: {
      stages: () => Stage.findAll()
    },
    methods: {
      getBranches() {
        this.loading_branches = true
        return Branch.findAll({
          limit,
          offset: this.offset,
          order_by: '-updated_at',
          filter: {
            committed_at__isnull: true,
            priority: 0
          }
        }, { bypassCache: true })
          .then(() => this.loading_branches = false)
      },
      setBranches() {
        this.branches = Branch.filter({
          limit: this.offset + page_size,
          orderBy,
          where: {
            committed_at: null,
            priority: 0
          }
        })
      },
      load() {
        this.getBranches().then(() => this.setBranches())
      },
      loadMore() {
        this.offset += page_size
        this.load()
      }
    },
    components: {
      branch
    },
    created() {
      this.load()
      socket.on('branches', Branch.manage)
      socket.on('branch_stages', BranchStage.manage)
      socket.on('branch_stage_accesses', BranchStageAccess.manage)
      api.hub.$on('branches', this.setBranches)
    },
    beforeDestroy() {
      socket.off('branches', Branch.manage)
      socket.off('branch_stages', BranchStage.manage)
      socket.off('branch_stage_accesses', BranchStageAccess.manage)
      api.hub.$off('branches', this.setBranches)
    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<template src="./index.html"></template>
<style src="./index.less" lang="less"></style>
