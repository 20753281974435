<script>

  import Tag from 'models/Tag'
  import Stage from 'models/Stage'
  import Branch from 'models/Branch'
  import Playlist from 'models/Playlist'
  import RecordingSource from 'models/RecordingSource'
  import Recording from 'models/Recording'
  import BranchStage from 'models/BranchStage'

  import video from 'services/video'
  import hls_settings from 'services/hls_settings'
  import asyncLoader from 'services/asyncLoad'

  import branchSelect from './branchSelect'
  import sceneSelect from './sceneSelect'
  import manualAlign from './manualAlign'

  import api, { socket } from 'api'

  export default {
    data() {
      let data = this.$route.meta.data
      return {
        params: undefined,
        step: 1,
        is_aligned: data.tags.find(t => t.end),
        is_initiated: !!data.branch_stage.task,
        is_finished: false,
        scene_selectable: false,
        is_processing_error: false,
        ref_recording: undefined,
        ref_branch: undefined,
        ref_points: []
      }
    },
    dataResolve: {
      branch() {
        return Branch.find(this.to.params.branch_id)
      },
      stage() {
        return Stage.findAll().then(stages => {
          let stage = stages.find(s => s.slug === 'align')
          if (!stage) {
            throw Error('No Stage')
          }
          return stage
        })
      },
      branch_stage: ['branch', 'stage', function(branch, stage) {
        return BranchStage.findAll({ 
          filter: { 
            branch_id: branch.id 
          } 
        }, { bypassCache: true })
          .then(branch_stages => {
            let branch_stage = branch_stages.find(b => b.stage_id === stage.id)
            if (!branch_stage) {
              throw Error('No Branch Stage')
            }
            if (branch.committed_at || branch_stage.committed_at) {
              this.next({
                name: 'studio',
                params: {
                  branch_id: branch.id,
                  stage_key: 'align'
                },
                query: { review: true }
              })
            }
            return branch_stage
          })
      }],
      recording: ['branch', branch => {
        return Recording.find(branch.recording_id).then(recording => {
          return recording.duration ? recording : video.getDuration(
            Playlist.get({ 
              recording_id: recording.id, 
              c: Math.random(),
            })
          ).then(duration => rec.DSUpdate({ duration }))
        })
      }],
      branches: ['recording', recording => {
        return Branch.findAll({
          filter: {
            committed_at__isnull: false,
            recording__catalog_item_id: recording.work_id
          },
        })
      }],
      tags: ['branch_stage', 'recording', (branch_stage, recording) => {
        return Tag.findAll({
          branch_stage_id: branch_stage.id,
          recording_id: recording.id,
          is_archived: false
        }, { bypassCache: true })
      }]
    },
    resolve: {
      reroute() {
        let { params, query } = this.to
        if (query.review) {
          this.next({
            name: 'studio',
            params: {
              branch_id: params.branch_id,
              stage_key: 'align'
            },
            query
          })
        }
      },
      player: () => asyncLoader.loadScript(hls_settings.getVersion().url),
      sources: () => RecordingSource.findAll()
    },
    methods: {
      branchSelected(branch) {
        this.ref_branch = branch
        this.ref_recording = branch.recording
        this.scene_selectable = true
      },
      aligned(params) {
        params.ref_branch_id = this.ref_branch.id
        this.params = params
      },
      execute() {
        if (this.isProcessing()) { return }
        this.branch_stage.align(this.params).then(() => {
          this.branch_stage.DSRefresh().then(bs => {
            this.rerender()
            this.$forceUpdate()
            this.watchBranchStageStatus()
          })
        })
        this.is_initiated = true
      },
      review() {
        this.$router.push({
          name: 'studio',
          params: {
            branch_id: this.branch.id,
            stage_key: this.stage.slug
          },
          query: { review: true }
        })
      },
      commit(comments) {
        this.branch_stage.comments = comments
        this.branch_stage.commit()
        this.$router.push(`/works/${this.recording.work.id}`)
      },
      rerender() {
        this.is_finished = this.is_initiated && !this.branch_stage.task
        if (this.branch_stage.task && this.branch_stage.task.status === 'error') {
          this.is_processing_error = true
        }
      },
      canGoBack() {
        switch (this.step) {
        case 3:
          return true
        case 2:
          return true
        default:
          return false
        }
      },
      goBack() {
        if (this.step === 2) {
          this.params = undefined
        }
        this.step -= 1
      },
      canGoNext() {
        switch (this.step) {
        case 1:
          return !!this.ref_branch
        case 2:
          return !!this.params
        default:
          return false
        }
      },
      goNext() {
        this.step += 1
      },
      isProcessing() {
        return this.branch_stage && this.branch_stage.task
      },
      watchBranchStageStatus() {
        if (this.branch_stage && this.branch_stage.task && this.branch_stage.task.status !== 'error') {
          this.timeout = setTimeout(() => {
            this.branch_stage.DSRefresh()
              .catch(() => {})
              .then(() => {
                this.rerender()
                this.$forceUpdate()
                this.watchBranchStageStatus()
              })
          }, 3000)
        }
      }
    },
    components: {
      branchSelect,
      sceneSelect,
      manualAlign
    },
    created() {
      let key = BranchStage.getSocketKey(this.branch_stage)
      socket.on('branch_stages', BranchStage.manage)
      api.hub.$on(key, this.rerender)
    },
    beforeDestroy() {
      let key = BranchStage.getSocketKey(this.branch_stage)
      socket.off('branch_stages', BranchStage.manage)
      api.hub.$off(key, this.rerender)
      clearTimeout(this.timeout)
    }
  }

</script>
<template src="./index.tpl.html"></template>
<style src="./index.less" lang="less"></style>
