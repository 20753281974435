import api from 'api'
// import { api.hub } from './';

const TagMaster = api.defineResource({
  name: 'TagMaster',
  endpoint: 'tagging/tag-masters',
  watchChanges: false,
  relations: {}
})

TagMaster.getCount = function(work_id) {
  return TagMaster.list(`count/?work_id=${work_id}&is_archived=true`).get()
}

export default TagMaster
