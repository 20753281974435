<template>

  <modal class="user-select" @cancel="cancel" >
    <h3 slot="header">Select a User</h3>
    <div slot="body">
      <div class="formfield">
        <input id="userselectfield" type="text" v-model="query" placeholder="search" autofocus autocomplete="off"
          @keydown.down.stop.prevent="down"
          @keydown.up.stop.prevent="up"
          @keydown.enter.stop.prevent="selectEnter"
          @keydown.esc.stop.prevent="cancel"
          @input="setUsers" >
      </div>

      <ul class="noselect" v-if="users.length">
        <li v-for="(user, index) in users" class="user" :class="activeClass(index)" @click="select(user)" @mousemove="setActive(index)" :key="index">
          <span>{{user.fullName()}} <span class="gray-lt">({{user.email}})</span></span>
        </li>
      </ul>

      <div v-else-if="query" class="empty">
        No Users Found
      </div>

      <div v-else class="empty">
        Search by name...
      </div>

    </div>
    <div slot="footer">
      <button class="modal-default-button" @click="$emit('cancel')">
        Cancel
      </button>
    </div>
  </modal>

</template>

<script>

  import User from 'models/User'

  import modal from 'components/modal'

  export default {
    data() {
      return {
        query: '',
        users: [],
        current: 0
      }
    },
    methods: {
      select(user) {
        this.$emit('select', user || this.users[this.current])
      },
      selectEnter(event){
        this.select(this.users[this.current])
      },
      cancel() {
        this.$emit('cancel')
      },
      up() {
        if (this.current > 0) {
          this.current--
        } else {
          this.current = this.users.length - 1
        }
      },
      down(event) {
        if (this.current < this.users.length - 1) {
          this.current++
        } else {
          this.current = 0
        }
      },
      setActive(index) {
        this.current = index
      },
      activeClass(index) {
        return {
          active: this.current === index
        }
      },
      setUsers() {
        if (!this.query) {
          this.users = User.getAll()
        } else {
          let regexp = new RegExp(this.query, 'i')
          this.users = User.getAll().filter(user => regexp.test(user.fullName()))
        }
        this.users.splice(5)
        this.current = 0
      }
    },
    created() {
      User.findAll({}, { bypassCache: true })
    },
    mounted() {
      document.getElementById('userselectfield').focus()
    },
    components: {
      modal
    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .user {
    cursor: pointer;
    padding: 1em 1em;
    &.active,
    &:hover {
      background-color: rgba(255,255,255,.25);
    }
  }
</style>
