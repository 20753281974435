import api from 'api'

const Recording = api.defineResource({
  name: 'Recording',
  endpoint: 'tagging/recordings',
  watchChanges: false,
  relations: {
    hasMany: {
      Branch: {
        localField: 'branches',
        foreignKey: 'recording_id'
      }
    },
    belongsTo: {
      User: {
        localField: 'user',
        localKey: 'user_id'
      },
      RecordingSource: {
        localField: 'source',
        localKey: 'source_id'
      },
      Work: {
        localField: 'work',
        localKey: 'work_id'
      }
    }
  },

  afterFind(resource, attrs, cb) {
    cb(null, attrs)
    api.hub.$emit(`recording.${attrs.id}`)
  },
  afterFindAll(resource, attrs, cb) {
    cb(null, attrs)
    api.hub.$emit('recording.findAll')
  },
  afterLoadRelations(resource, attrs, cb) {
    cb(null, attrs)
    api.hub.$emit('recording.loadRelations')
  },
  afterInject(resource, attrs) {
    attrs = Array.isArray(attrs) ? attrs : [attrs]
    attrs.forEach(recording => {
      api.hub.$emit(`work.${recording.work_id}.recordings`)
    })
  },
  afterEject(resource, attrs) {
    attrs = Array.isArray(attrs) ? attrs : [attrs]
    attrs.forEach(recording => {
      api.hub.$emit(`work.${recording.work_id}.recordings`)
    })
  }
})

Recording.safeInject = recording => {
  Recording.inject(recording)
  api.hub.$emit(`recordings`)
  api.hub.$emit(`recording.${recording.id}`)
  api.hub.$emit(`work.${recording.work_d}.recordings`)
}

export default Recording
