<template>
  <transition name="modal">
    <div class="modal-mask" @click="offclick" ref="mask">
      <div class="modal-wrapper" ref="wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header"></slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <p>default body</p>
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <button class="modal-default-button" @click="$emit('cancel')">
                Cancel
              </button>
              <button class="modal-default-button" @click="$emit('close')">
                OK
              </button>
            </slot>
          </div>

        </div>
      </div>
    </div>
  </transition>
</template>

<script>

  export default {
    props: ['hideClose'],
    methods: {
      offclick(event) {
        let { mask, wrapper } = this.$refs
        if (event.target === mask || event.target === wrapper) {
          this.$emit('offclick')
        }
      },
      keypress(event) {
        if (event.keyCode === 27) {
          this.$emit('offclick')
        }
      }
    },
    created() {
      window.addEventListener('keyup', this.keypress)
    },
    destroyed() {
      window.removeEventListener('keyup', this.keypress)
    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./index.less" lang="less"></style>
