/* ============
 * Main File
 * ============
 *
 * Will initialize the application
 */
import Vue from 'vue'
import Polyfill from 'services/polyfill' // eslint-disable-line no-unused-vars
import GlobalFilters from 'services/globalFilters'
import ResolveMixin from 'mixins/resolve'
import App from './app.vue'

Vue.use(GlobalFilters)
Vue.mixin(ResolveMixin)

new Vue(App).$mount('#app')
