<script>

  import q from 'q'
  import BranchStage from 'models/BranchStage'
  import BranchStageAccess from 'models/BranchStageAccess'

  import toast from 'services/toast'
  import Auth from 'services/api/auth'
  import dropdown from 'components/dropdown'

  import userSelect from 'components/users/select'
  import SessionData from 'components/sessionData'

  export default {
    props: ['stage', 'branch', 'branch_stage'],
    data() {
      return {
        viewing_branch_stage_session_data: false,
        dropping: false,
        assigning: false
      }
    },
    methods: {
      activateDropdown() {
        this.dropping = !!Auth.user.is_super
      },
      getAccesses(include_expired) {
        return !this.branch_stage ? [] : this.branch_stage.getAccesses(include_expired)
      },
      viewSessionData() {
        this.viewing_branch_stage_session_data = true
      },
      create() {
        return BranchStage.create({
          branch_id: this.branch.id,
          stage_id: this.stage.id
        }).then(branch_stage => {
          this.$emit('created', branch_stage)
          return branch_stage
        })
      },
      assign(user) {
        this.assigning = false
        let get = this.branch_stage ? q.resolve(this.branch_stage) : this.create()
        return get.then(branch_stage => {
          return BranchStageAccess.create({
            branch_stage_id: branch_stage.id,
            user_id: user.id
          })
        }).then(() => this.$emit('assigned'))
      },
      unassign(access) {
        access.DSUpdate({
          expired_at: new Date()
        }).then(() => this.$emit('unassigned'))
      },
      commit() {
        let confirm = window.confirm('Are you sure you want to commit this!?')
        if (confirm) {
          this.branch_stage.commit().then(() => this.$emit('committed'))
        }
      },
      uncommit() {
        let confirm = window.confirm('Are you sure you want to uncommit this!?')
        if (confirm) {
          this.branch_stage.uncommit().then(() => this.$emit('uncommitted'))
        }
      },
      destroy() {
        let confirm = window.confirm('Are you sure you want to delete this!? It\'s a big deal!')
        if (confirm) {
          this.branch_stage.DSDestroy()
            .then(() => this.$emit('destroyed'))
            .catch(error => {
              if (error.status === 404) {
                this.branch_stage.DSEject()
                this.$emit('destroyed')
              } else {
                toast(error.data.message, { theme: 'error' })
              }
            })
        }
      }
    },
    computed: {
      is_committed() {
        return this.branch_stage && this.branch_stage.committed_at
      }
    },
    components: {
      SessionData,
      userSelect,
      dropdown
    }
  }
</script>
<template src="./index.tpl.html"></template>
<!-- <style src="./index.less" lang="less"></style> -->
