<script>

  import Auth from 'services/api/auth'
  import Advisory from 'models/Advisory'

  import modal from 'components/modal'
  import dropdown from 'components/dropdown'
  import workAdvisories from 'components/work/advisories'

  export default {
    props: ['work', 'recording', 'branch_stage', 'branch_stage_access'],
    data() {
      return {
        show: false,
        position: ['right', 'bottom', 'right', 'top'],
        modal_comments: false,
        modal_advisories: false,
        user: Auth.user,
        comments: this.branch_stage.comments,
        advisories: Advisory.getAll()
      }
    },
    methods: {
      comment() {
        this.modal_comments = true
      },
      advise() {
        this.modal_advisories = true
      },
      commit() {
        this.$emit('commit')
      },
      unassign() {
        this.$emit('unassign')
      },
      committed() {
        return !!this.branch_stage.committed_at
      },
      unassignable() {
        return !this.branch_stage.committed_at && this.branch_stage_access && this.branch_stage_access.user === this.user
      },
      closeComments() {
        if (!this.committed()) {
          this.branch_stage.comments = this.comments
          this.branch_stage.updateComments()
        }
        this.modal_comments = false
      },
      closeAdvisories() {
        if (!this.committed()) {
          this.$refs.advisories.update()
        }
        this.modal_advisories = false
      }
    },
    components: {
      workAdvisories,
      dropdown,
      modal
    }
  }
</script>
<template src="./index.html"></template>
<style src="./index.less" lang="less"></style>
