import api from 'api'

const Season = api.defineResource({
  name: 'Season',
  endpoint: 'tagging/seasons',
  cacheResponse: true,
  relations: {
    belongsTo: {
      Show: {
        localField: 'show',
        localKey: 'show_id'
      }
    },
    hasMany: {
      Work: {
        localField: 'episodes',
        foreignKey: 'season_id'
      }
    }
  },
  methods: {

  },
  watchChanges: false
})

export default Season
