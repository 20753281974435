<script>

  import Playlist from 'models/Playlist'
  import player from 'components/player'
  import transform from './transform'

  const points_ref = [0.15, 0.5, 0.85, 0.3, 0.7, 0.1, 0.9, 0.4, 0.6]

  export default {
    props: ['recording', 'ref_recording'],
    data() {
      return {
        t: {},
        ready: [false, false],
        time: (this.recording.duration * 0.15 || 0),
        ref_time: (this.ref_recording.duration * 0.15 || 0),
        ref_point_index: 0,
        ref_points: [[], []],
        ref_points_min: 3,
        ref_active: undefined,
        source: Playlist.get({ 
          recording_id: this.recording.id
        }, {}),
        refSource: Playlist.get({ 
          recording_id: this.ref_recording.id
        }, {})
      }
    },
    components: {
      player
    },
    methods: {
      isLoading() {
        return this.ready.filter(d => d).length < 2
      },
      setTime(time) {
        this.time = time
        this.ready[0] = true
      },
      setRefTime(time) {
        this.ref_time = time
        this.ready[1] = true
      },
      setPlayers(index) {
        let arr = this.ref_points,
            m = points_ref[index],
            a = arr[0][index - 1] || 0,
            b = arr[1][index - 1] || 0
        this.ready = [false, false]
        this.$refs.player.time(this.recording.duration * m)
        this.$refs.ref_player.time(this.ref_recording.duration * m + (b - a))
        this.ref_active = this.$refs.player
      },
      keydown(event) {
        if (!this.ref_active || ['INPUT', 'TEXTAREA', 'SELECT'].indexOf(event.target.nodeName) > -1) {
          return
        }
        if (event.keyCode === 9) {
          this.ref_active = this.ref_active === this.$refs.player ? this.$refs.ref_player : this.$refs.player
          return event.preventDefault()
        }
        this.ref_active.keyEvent(event)
      },
      setIndex(index) {
        this.ref_point_index = index
        this.setPlayers(index)
      },
      setRefPoint() {
        if (this.isLoading()) { return }
        let arr = this.ref_points
        arr[0][this.ref_point_index] = this.$refs.player.time()
        arr[1][this.ref_point_index] = this.$refs.ref_player.time()
        this.t = arr[0].length ? transform(arr[1], arr[0]) : {}
        if (arr[0].filter(d => d).length >= this.ref_points_min) {
          this.$emit('aligned', this.t)
        }
      }
    },
    created() {
      document.addEventListener('keydown', this.keydown)
    },
    mounted() {
      this.ref_active = this.$refs.player
    },
    beforeDestroy() {
      document.removeEventListener('keydown', this.keydown)
    }
  }
</script>
<template src="./index.tpl.html"></template>
<style src="./index.less" lang="less"></style>
