<template>
  <div class="timeline"></div>
</template>

<script>
  import * as d3 from 'd3'

  import hub from 'services/hub'

  import Focus from './focus/focus'
  import Context from './context/context'

  export default {
    data() {
      return {}
    },
    props: ['time', 'tags', 'work', 'recording'],
    methods: {
      init() {
        let self = this,
            parent_element = this.$el,
            parent_width = parent_element.clientWidth,
            parent_height = parent_element.clientHeight,

            margin = { left: 3, right: 3, top: 3, bottom: 1, inbetween: 20 },
            width = parent_width - margin.left - margin.right,
            height = parent_height - margin.top - margin.bottom,

            context_ratio = 0.075,
            focus_ratio = 1 - context_ratio,
            context_dims = {
              y: margin.top,
              width: parent_width - margin.left - margin.right,
              height: context_ratio * (height - margin.inbetween)
            },
            focus_dims = {
              y: context_dims.height + margin.top + margin.inbetween,
              width: parent_width - margin.left - margin.right,
              height: focus_ratio * (height - margin.inbetween)
            }

        let svg = d3.select(parent_element).append('svg')
          .attr('width', parent_width)
          .attr('height', parent_height)
          .attr('xmlns', 'https://www.w3.org/2000/svg')
          .attr('xlink', 'https://www.w3.org/1999/xlink')

        let brush = d3.brushX()
          .extent([[0, 0], [width, context_dims.height]])
          .on('brush', brushed)
          .handleSize(3)

        let zoom = this.zoom = d3.zoom()
          .extent([[0, 0], [width, height]])
          .translateExtent([[0, 0], [width, height]])
          .on('zoom', zoomed)

        let g = this.g = svg.append('g')
          .attr('transform', `translate(${margin.left},${margin.top})`)
          .call(zoom)
          .on('dblclick.zoom', zoomDoubleClick)
          .on('wheel.zoom', wheeled)

        g.append('rect')
          .attr('class', 'background')
          .attr('width', '100%')
          .attr('height', '100%')

        let focus_wrap = this.appendWrap(g, 0, focus_dims.y),
            focus = this.focus = Focus.build(focus_wrap, focus_dims)

        let context_wrap = this.appendWrap(g, 0, context_dims.y),
            context = this.context = Context.build(context_wrap, context_dims, brush)

        let drag = d3.drag()
          .on('drag', cursorDragged)

        focus.cursor.g.call(drag)
        focus.cursor.ghost.g.call(drag)

        focus.cursor.ghost.backdrop.on('click', cursorClicked)

        let contextDrag = d3.drag()
          .on('drag', contextCursorDragged)

        context.cursor.g.call(contextDrag)

        function wheeled() {
          let node = g.node(),
              event = d3.event,
              dx = event.deltaX,
              dy = event.deltaY,
              dx_abs = Math.abs(dx),
              dy_abs = Math.abs(dy),
              t = d3.zoomTransform(node)
          if (dx_abs > dy_abs) {
            zoom.translateBy(g, -dx * 2 / t.k)
            tUpdate(d3.zoomTransform(node))
          } else if (dx_abs < dy_abs) {
            // Rescale
            let k = t.k * Math.pow(2, -event.deltaY * (event.deltaMode ? 120 : 1) / 500)
            zoom.scaleTo(g, k)
            // Retranslate
            let t2 = d3.zoomTransform(node),
                p = d3.mouse(node),
                w = context.scale.x.range()[1],
                dw = (w / t2.k) - (w / t.k),
                x = dw / 2 - dw * (p[0] / w)
            zoom.translateBy(g, -x, 0)
            tUpdate(d3.zoomTransform(node))
          }
          return event.preventDefault && event.preventDefault()
        }

        function cursorClicked() {
          let time = focus.scale.x.invert(d3.event.offsetX - 3)
          self.renderCursor()
          self.$emit('cursorMoved', time)
        }

        function cursorDragged() {
          let time = focus.scale.x.invert(d3.event.x)
          self.renderCursor()
          self.$emit('cursorMoved', time)
        }

        function contextCursorDragged() {
          let time = context.scale.x.invert(d3.event.x)
          self.renderCursor()
          self.$emit('cursorMoved', time)
        }

        function zoomDoubleClick() {
          // let e = d3.event;
        }

        function brushed() {
          if (d3.event.sourceEvent && (d3.event.sourceEvent.type === 'brush' ||
            d3.event.sourceEvent.type === 'wheel')) { return }
          let range = d3.event.selection || context.scale.x.range(),
              domain = range.map(context.scale.x.invert, context.scale.x)
          focus.setDomain(domain)
          let r = context.scale.x.range(),
              k = (r[1] - r[0]) / (range[1] - range[0])
          self.setView(k, range[0])
          focus.redrawTags()
          self.renderCursor()
        }

        function zoomed() {
          if (d3.event.sourceEvent && (d3.event.sourceEvent.type === 'brush' ||
            d3.event.sourceEvent.type === 'wheel')) { return }
          tUpdate(d3.event.transform)
        }

        function tUpdate(transform) {
          focus.setDomain(transform.rescaleX(context.scale.x).domain())
          focus.redrawTags()
          context.moveBrush(focus.scale.x.range().map(transform.invertX, transform))
          self.renderCursor()
        }

        // function isInContext(event) {
        //     let t = event.target,
        //         c = context.g.node(),
        //         gn = g.node(),
        //         stop;
        //     do {
        //         t = t.parentNode;
        //         stop = t === gn || t === c;
        //     } while (t && !stop);
        //     return t === c;
        // }
      },

      updateTags() {
        this.focus.updateTags(this.getTags() || [])
      },

      setView(k, x, duration) {
        if (duration) {
          this.zoom.scaleExtent([1, duration / 10])
        }
        let identity = d3.zoomIdentity.scale(k).translate(-x, 0)
        this.g.call(this.zoom.transform, identity)
      },

      setRecording(recording, tags) {
        this.focus.setDomain([0, recording.duration])
        this.context.setDomain([0, recording.duration])

        this.setView(1, 0, recording.duration)
        this.focus.updateTags(tags || [])
        this.renderCursor()
      },

      renderCursor() {
        this.focus.redrawCursor(this.time)
        this.context.redrawCursor(this.time)
      },

      appendWrap(g, x, y) {
        let wrap = Object.create(this)
        wrap.g = g.append('g').attr('transform', `translate(${x},${y})`)
        return wrap
      },

      reportEvent(event, data) {
        if (!event) { return }
        let emitter = (event === 'cursor.moved' ? hub : this)
        emitter.$emit(event, data)
      },
      getTags() {
        return this.tags.filter(tag => tag.tag_instance_id)
      }
    },
    watch: {
      tags() {
        this.updateTags()
      },
      time() {
        this.renderCursor()
      }
    },
    mounted() {
      this.init()
      this.setRecording(this.recording, this.getTags())
    },
    destroyed() {

    }
  }

</script>
<style src="./index.less" lang="less"></style>
