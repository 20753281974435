import Vue from 'vue'
import io from 'socket.io-client'
import http from 'services/api/adapters/http'
import JSData from 'js-data'

const rootPath = process.env.VUE_APP_API_HOST,
      basePath = rootPath + 'api/',
      url = (path, is_root) => (is_root ? rootPath : basePath) + path,
      api = new JSData.DS({ basePath })

// Rest Methods to API
Object.assign(api, {
  getHeaderKeys() {
    return Object.keys(http.defaults.httpConfig.headers)
  },
  setHeader(header, value) {
    http.defaults.httpConfig.headers[header] = value
  },
  deleteHeader(header) {
    delete http.defaults.httpConfig.headers[header]
  },
  GET(path, params, is_root) {
    return http.GET(url(path, is_root), params)
  },
  PUT(path, data, params, is_root) {
    return http.PUT(url(path, is_root), data, { params })
  },
  POST(path, data, params, is_root) {
    return http.POST(url(path, is_root), data, { params })
  },
  DELETE(path, params, is_root) {
    return http.DEL(url(path, is_root), { params })
  }
})

// /* Url getter */
api.getUrl = function(model) {
  return api.defaults.basePath + api.definitions[model].endpoint
}
api.defaults.methods.getUrl = function() {
  return api.getUrl(this.constructor.name) + '/' + this.id
}

/* Patch */
api.patch = function(model, id, attrs, opts = {}) {
  opts.method = 'PATCH'
  return api.update(model, id, attrs, opts)
}

api.defaults.methods.DSPatch = function(attrs, opts) {
  return api.patch(this.constructor.name, this.id, attrs, opts)
}

// /* List */
api.list = function(model, action) {
  return getActions(this.getUrl(model), action)
}

api.manage = function(name, resource) {
  let model = api.definitions[name]
  if (typeof resource === 'object') {
    let inject = model.safeInject || model.inject
    return inject.call(model, resource)
  } else {
    return model.eject(resource)
  }
}

api.findOne = function(name, params, options = {}) {
  let model = api.definitions[name]
  return model.findAll(params, options).then(instances => {
    if (options.strict) {
      let n = instances.length
      if (n > 1) throw Error(`More than one ${name} found`)
      if (n < 1) throw Error(`No ${name} found with given params`)
    }
    return instances[0]
  })
}

// /* Detail */
api.detail = function(model, id, action) {
  return getActions(api.getUrl(model) + '/' + id, action)
}

api.defaults.methods.DSDetail = function(action) {
  return getActions(this.getUrl(), action)
}

api.registerAdapter('http', http, { default: true })

function getActions(base, action) {
  let url = `${base}/${action}`,
      then = r => r.data
  return {
    get: params => http.GET(url, { params }).then(then),
    put: (payload, params) => http.PUT(url, payload, { params }).then(then),
    post: (payload, params) => http.POST(url, payload, { params }).then(then),
    del: params => http.DEL(url, { params }).then(then)
  }
}

export const socket = io.connect(process.env.VUE_APP_SOCKET_URL, {
  reconnection: true,
  // reconnectionDelay: 1000,
  reconnectionDelay: 100000000,
  // reconnectionDelayMax: 5000,
  reconnectionDelayMax: 50000000,
  reconnectionAttempts: 0,
  // reconnectionAttempts: Infinity
})

api.hub = new Vue()

export default api