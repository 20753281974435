<template><div></div></template>
<script>
  import Auth from 'api/auth'
  export default {
    beforeCreate() {
      Auth.logout()
      this.$router.push('/')
    }
  }
</script>
