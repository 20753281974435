
let _host

function toast(string, opts = {}) {
  if (!('timeLife' in opts)) { opts.timeLife = 3000 };
  return _host && _host.showToast(string, opts)
};

toast.init = function(host) {
  return (_host = host.setOptions({
    position: 'right bottom'
  }))
}

export default toast
