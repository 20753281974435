const
  query = window.location.search.slice(1),
  utm_data = query.split('&').reduce((qs, str) => {
    let pair = str.split('='),
        key = decodeURIComponent(pair[0]),
        value = pair[1] === undefined ? pair[1] : `${decodeURIComponent(pair[1])}`
    if (/^utm/.test(key)) {
      qs[key] = value
    }
    return qs
  }, {})


class AnalyticsUtils {

  getParams(obj) {
    let str = ''
    Object.forEach(obj, (val, key) => {
      return str += (str ? '&' : '?') + key + '=' + val
    })
    return str
  }

  getUTMParams() {
    return utm_data
  }

  getRouteParams(route = {}) {
    let { path, fullPath } = route
    return { path, fullPath }
  }

  getWorkInfo(work = {}) {
    return [
      'id',
      'title',
      'subtitle',
      'show_name',
      'show_slug',
      'season_number',
      'episode_number',
      'rating'
    ].reduce((d, key) => {
      d[`work_${key}`] = work[key]
      return d
    }, {})
  }
}

export default new AnalyticsUtils()
