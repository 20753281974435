<template>
  <dropdown class="branch-actions-menu" :visible="show" animation="ani-slide-y" @clickAny="show=false" :position="drop_position">
    <span @click="show=true" class="action">
      <slot name="handle"></slot>
    </span>
    <div slot="dropdown" >
      <div v-if="!branch.committed_at">
        <div class="hline">Set Priority</div>
        <div class="line" @click="setPriority(4)"><i class="fa fa-fw fa-circle priority-4"></i>Urgent</div>
        <div class="line" @click="setPriority(3)"><i class="fa fa-fw fa-circle priority-3"></i>High</div>
        <div class="line" @click="setPriority(2)"><i class="fa fa-fw fa-circle priority-2"></i>Med.</div>
        <div class="line" @click="setPriority(1)"><i class="fa fa-fw fa-circle priority-1"></i>Low</div>
        <div class="line" @click="setPriority(0)"><i class="fa fa-fw fa-circle priority-0"></i>None</div>
        <hr>
        <div v-if="is_committable">
          <div class="line" @click="commit()" ><i class="fa fa-fw fa-lock"></i>Commit</div>
          <hr>
        </div>
        <div class="line" @click="pause(false)" v-if="branch.is_paused"><i class="fa fa-fw fa-play"></i>Resume</div>
        <div class="line" @click="pause(true)" v-else><i class="fa fa-fw fa-pause"></i>Pause</div>
        <div class="line" @click="destroy()"><i class="fa fa-fw fa-trash"></i>Delete</div>
      </div>
      <div v-else>
        <div class="linewrap">Status: Committed</div>
        <div class="linewrap detail" v-if="branch.creator">
          <div>Created by {{branch.creator.fullName()}}</div>
          <div>{{branch.created_at | date('MMM D, YYYY h:mm A')  }}</div>
        </div>
        <div class="linewrap detail" v-if="branch.committer">
          <div>Committed by {{branch.committer.fullName()}}</div>
          <div>{{branch.committed_at | date('MMM D, YYYY h:mm A')  }}</div>
        </div>
        <div>
          <hr>
          <div class="line" @click="exportBranch()"><i class="fa fa-fw fa-paper-plane"></i>Export</div>
        </div>
      </div>
    </div>
  </dropdown>
</template>

<script type="text/javascript">
  import api from 'services/api'
  import Stage from 'models/Stage'
  import toast from 'services/toast'
  import dropdown from 'components/dropdown'
  
  const env = process.env.VUE_APP_ENV

  export default {
    props: ['branch', 'position'],
    data() {
      return {
        env,
        show: false,
        drop_position: this.position || ['right', 'bottom', 'right', 'top']
      }
    },
    methods: {
      setPriority(priority) {
        this.update({ priority })
      },
      commit() {
        let confirm = window.confirm('Are you sure you want to commit & close this revision?')
        if (confirm) {
          this.branch.commit().then(() => this.render())
        }
      },
      exportBranch() {
        let confirm = window.confirm('Are you sure you want to export this Recording?')
        if (confirm) {
          this.branch.export()
            .then(() => alert('Recording export initiated'))
            .then(() => this.render())
            .catch(error => toast(error.data.message, { theme: 'error' }))
        }
      },
      pause(is_paused) {
        this.update({ is_paused })
      },
      update(payload) {
        return this.branch.DSUpdate(payload).then(() => this.render())
      },
      destroy() {
        let confirm = window.confirm('Are you sure you want to delete this revision?')
        if (confirm) {
          this.branch.DSDestroy()
            .then(() => this.render())
            .catch(err => toast(err.data.message, { theme: 'error' }))
        }
      },
      nextStage() {
        return Stage.getNext(this.branch)
      },
      render() {
        this.$forceUpdate()
      }
    },
    computed: {
      is_committable() {
        return !this.branch.committed_at && !this.nextStage() && !this.branch.branch_stages.find(bs => !bs.committed_at)
      }
    },
    components: {
      dropdown
    },
    created() {
      api.hub.$on(`branch.${this.branch.id}.branch_stages`, this.render)
    },
    beforeDestroy() {
      api.hub.$off(`branch.${this.branch.id}.branch_stages`, this.render)
    }

  }
</script>

<style type="text/css" lang="less">

  @import '~src/assets/styles/variables.less';

  .branch-actions-menu {
    .ddown {
      text-align: left;
    }
  }

</style>
