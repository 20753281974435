export default function(el, speeds, speed) {
  if (arguments.length > 2) {
    if (typeof speed !== 'number') {
      let r = el.playbackRate,
          i = speeds.indexOf(r) + (speed ? 1 : -1)
      speed = speeds[i] || r
    }
    el.playbackRate = speed
  }
  return this.$refs.video.playbackRate
};
