<template>

  <modal class="stage-select" @cancel="cancel" >
    <h3 slot="header">Select Revision Stages</h3>

    <div slot="body">
      <div v-if="loading" class="loading">
        <img src="/static/img/misc/load_on_black.gif">
      </div>
      <div v-else>
        <div class="yellow" v-if="committed_exists">NOTE! Tags already exist for this work! Unless there is a special reason not to, please use an importing stage (like Align & Review) to bring tags to this recording!</div>
        <ul class="noselect" v-if="sets.length" >
          <li v-for="(set, index) in sets" class="stage" :class="activeClass(index)" @click="select(set)" @mousemove="setActive(index)" :key="index">
            <span v-for="(stage, index) in set" :key="index">{{stage.title}} <span v-if="index !== set.length-1" class="gray-l">&nbsp;&nbsp;>&nbsp;&nbsp;</span> </span>
          </li>
        </ul>
        <div v-else class="empty">
          No Revision Trees Found
        </div>
      </div>
      <div class="formline-errors" v-if="error">
        {{error.message}}
      </div>
    </div>
    <div slot="footer">
      <button class="modal-default-button" @click="$emit('cancel')">
        Cancel
      </button>
    </div>
  </modal>

</template>

<script>

  import toast from 'services/toast'
  import Stage from 'models/Stage'
  import TagMaster from 'models/TagMaster'
  import modal from 'components/modal'

  export default {
    props: ['recording'],
    data() {
      let recording = this.recording,
          work = recording && recording.work
      return {
        loading: true,
        error: null,
        committed_exists: !recording.branches.length && work.master_tags.length,
        query: '',
        sets: [],
        current: 0
      }
    },
    methods: {
      select(set) {
        set = set || this.sets[this.current]
        this.$emit('select', set[0])
      },
      cancel() {
        this.$emit('cancel')
      },
      up() {
        if (this.current > 0) {
          this.current--
        } else {
          this.current = this.sets.length - 1
        }
      },
      down(event) {
        if (this.current < this.sets.length - 1) {
          this.current++
        } else {
          this.current = 0
        }
      },
      setActive(index) {
        this.current = index
      },
      activeClass(index) {
        return {
          active: this.current === index
        }
      },
      setSets() {
        this.sets = Stage.getSets()
      }
    },
    created() {
      this.loading = true
      Stage.findAll({}, { bypassCache: true })
        .then(() => this.setSets())
        .catch(error => {
          toast(error.data.message, { theme: 'error' })
        })
        .then(() => this.loading = false)
      if (this.recording && this.recording.work) {
        TagMaster.getCount(this.recording.work.id).then(count => {
          this.committed_exists = !this.recording.branches.length && count
        })
      }
    },
    components: {
      modal
    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .stage {
    font-size: 1.15em;
    cursor: pointer;
    padding: 1em 1em;
    &.active,
    &:hover {
      background-color: rgba(0,0,0,.1);
    }
  }
  .stage-select {
    .loading {
      margin: 4em auto 3em auto;
    }
  }
</style>
