import api from 'api'

const BranchStageAccess = api.defineResource({
  name: 'BranchStageAccess',
  endpoint: 'tagging/branch-stage-accesses',
  relations: {
    belongsTo: {
      User: {
        localField: 'user',
        localKey: 'user_id'
      },
      BranchStage: {
        localField: 'branch_stage',
        localKey: 'branch_stage_id'
      }
    }
  },
  methods: {
    unassign() {
      return this.DSDetail('unassign').put()
        .then(() => BranchStageAccess.eject(this.id))
    },
    getDuration() {
      return new Date(this.expired_at).getTime() - new Date(this.created_at).getTime()
    }
  },
  afterFind(resource, attrs, cb) {
    cb(null, attrs)
    api.hub.$emit(`branch_stage_access.${attrs.id}`)
  },
  afterUpdate(resource, attrs, cb) {
    cb(null, attrs)
    api.hub.$emit(`branch_stage_access.${attrs.id}`)
  },
  afterCreate(resource, attrs, cb) {
    cb(null, attrs)
    api.hub.$emit(`branch_stage_access.${attrs.id}.create`)
  },
  afterDestroy(resource, attrs, cb) {
    cb(null, attrs)
    api.hub.$emit(`branch_stage_access.${attrs.id}.destroy`)
  },
  afterEject(resource, attrs) {
    attrs = Array.isArray(attrs) ? attrs : [attrs]
    attrs.forEach(branch_stage_access => {
      api.hub.$emit(`branch.${branch_stage_access.branch_stage_id}.branch_stage_accesses`)
    })
  },
  watchChanges: false
})

BranchStageAccess.safeInject = branch_stage_access => {
  BranchStageAccess.inject(branch_stage_access)
  api.hub.$emit(`branch_stage_accesses`)
  api.hub.$emit(`branch_stage.${branch_stage_access.branch_stage_id}.branch_stage_accesses`)
}

export default BranchStageAccess
