import route from 'router/routeAdapter'

import Auth from 'pages/auth';
import Logout from 'pages/auth/logout';

import PagesHome from 'pages/home'
import Backlog from 'pages/home/backlog'
import Checkouts from 'pages/home/checkouts'
import Home from 'pages/home/home'
import StudioAlign from 'pages/home/studioAlign'
import Studio from 'pages/home/studio'
import Works from 'pages/home/works'
import WorkSources from 'pages/home/works/sources'
import Shows from 'pages/home/shows'
import Show from 'components/show'
import Season from 'components/season'
import Episode from 'components/work/asEpisode'

import Account from 'pages/account'
import Admin from 'pages/admin'

import Hmm from 'components/maintenance'

export default [

  route('', PagesHome, { meta: { private: true, on_error: '/hmm' }, children: [

    route('/', Home),
    route('/backlog', Backlog, { meta: { private: ['super'] } }),
    route('/checkouts', Checkouts),
    route('/works/:id', Works, { name: 'work', redirect: to => `${to.path}/sources/1`, children: [
      route('sources/:source_id', WorkSources, { name: 'work-sources' })
    ] }),

    route('/shows', Shows),
    route('/shows/:show_id', Show, { name: 'show', children: [
      route('season/:season_number', Season, { name: 'season', children: [
        route('episode/:episode_number', Episode, { name: 'episode' })
      ] })
    ] }),

    route('/studio/:branch_id/align', StudioAlign, { name: 'studio-align' }),
    route('/studio/:branch_id/:stage_key', Studio, { name: 'studio', meta: { is_fullscreen: true } }),
    route('/account', Account)

  ] }),

  route('/logout',                      Logout),
  route('',                             Auth, { meta: { public: true }, children: [
    route('/login',                     null, { beforeEnter: () => window.location = process.env.VUE_APP_LOGIN_URL }),
  ]}),

  route('/admin', Admin, { meta: { private: ['super'] } }),

  route('/hmm', Hmm, { meta: { nocheck: true } }),

  {
    path: '/*',
    redirect: '/'
  }
]
