<template>

  <div class="work-advisories">

    <h3>Advisories</h3>

    <p v-if="!readOnly">If this movie contains items that were unable to be removed, select which categories apply (please use sparingly)</p>

    <label class="formline" v-for="(advisory, i) in advisories" :key="advisory.id">
      <span v-if="readOnly" class="read-only-advisory">
        <i class="fa fa-fw" :class="temp[advisory.id] ? ' fa-check-circle theme-lt' : 'fa-circle-thin'"></i>
      </span>
      <input :tabindex="getTabIndex(i)" type="checkbox" v-model="temp[advisory.id]" v-else>
      <span class="noselect">{{advisory.title}}</span>
    </label>

  </div>
</template>

<script>

  import WorkAdvisory from 'models/WorkAdvisory'

  export default {
    props: ['work', 'advisories', 'tabStart', 'readOnly'],
    data() {
      return {
        temp: this.work.getAdvisoryMap()
      }
    },
    methods: {
      getTabIndex(i) {
        return (this.tabStart || 0) + i
      },
      update() {
        return WorkAdvisory.resolveMap(this.work, this.temp)
      }
    }
  }

</script>
<style lang="less">
  .read-only-advisory {
    font-size: 1.35em;
  }
</style>
