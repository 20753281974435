import api from 'api'

const Stage = api.defineResource({
  name: 'Stage',
  endpoint: 'tagging/stages/',
  relations: {
    belongsTo: {
      Stage: {
        localField: 'child',
        localKey: 'child_id'
      }
    },
    hasOne: {
      Stage: {
        localField: 'parent',
        foreignKey: 'child_id'
      }
    }
  },
  methods: {
    getSet() {
      let up = this,
          down = this,
          tree = [this]
      while (up = up.parent) {
        tree.unshift(up)
      }
      while (down = down.child) {
        tree.push(down)
      }
      return tree
    }
  },
  watchChanges: false
})

Stage.getNext = function(branch) {
  let branch_stages = branch.branch_stages
  if (!branch_stages.length) {
    return branch.ref_stage
  }
  return branch_stages.find(bs => !branch_stages.find(bs2 => bs2.parent_id === bs.id)).stage.child
}

Stage.getSets = function(include_archived) {
  let stages = Stage.getAll()
  return stages
    .filter(stage => {
      let is_root = !stages.find(stg => stg.child_id === stage.id)
      return include_archived ? is_root : (!stage.is_archived && is_root)
    })
    .filter(stage => !stage.is_computed)
    .map(stage => stage.getSet())
}

export default Stage
