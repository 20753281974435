<script>

  import field from 'components/form/field'

  export default {
    props: ['tag'],
    methods: {
      close() {
        this.$emit('close')
      }
    },
    components: {
      field
    }
  }
</script>
<template src="./index.tpl.html"></template>
<style src="./index.less" lang="less"></style>
