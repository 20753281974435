<script>

  import Auth from 'services/api/auth'
  import deltas from 'components/branchStage/deltas'
  import branchSummary from 'components/branch/detail/summary'

  export default {
    props: ['branch'],
    data() {
      return {
        me: Auth.user,
        summary: false
      }
    },
    created() {
      this.work = this.branch.recording.work
      this.branch_stage = this.branch.branch_stages.find(bs => {
        return bs.accesses.find(access => !access.is_revoked && access.user_id === Auth.user.id)
      })
    },
    components: {
      branchSummary,
      deltas
    }
  }
</script>
<template src="./index.tpl.html"></template>
<style src="./index.less" lang="less"></style>
