<script>

  import tagTable from './table'
  import tagEdit from 'components/tag/edit'
  import tagView from 'components/tag/view'
  import tagBulkApply from 'components/tag/bulkApply'

  export default {
    props: ['tags', 'tagmap', 'branch_stage', 'time'],
    data() {
      return {
        search: '',
        is_applied: true,
        is_full_description: false,
        read_only: !!this.branch_stage.committed_at,
        tag_attrs: false,
        bulk_applying: !!this.tags.filter(t => !t.isApplied()).length,
        tag_view: undefined
      }
    },
    methods: {
      newTag(type) {
        if (this.read_only) return
        this.tag_attrs = {
          begin: this.time,
          end: this.time,
          branch_stage_id: this.branch_stage.id,
          type
        }
      },
      editTag(tag) {
        if (this.branch_stage.committed_at) {
          this.tag_view = tag
        } else {
          this.tag_attrs = tag
        }
      },
      viewTag(tag) {
        if (!tag.isActivable()) { return }
        this.$emit('viewTag', tag)
      },
      toggleTag(tag) {
        if (!tag.isActivable()) { return }
        this.tagmap[tag.id] = !this.tagmap[tag.id]
        this.tagmapChanged()
      },
      toggleAllTags() {
        let status = !this.activableTags.find(tag => this.tagmap[tag.id])
        this.activableTags.forEach(tag => this.tagmap[tag.id] = status)
        this.$refs.tag_table.$forceUpdate()
        this.tagmapChanged()
      },
      closeView() {
        this.tag_view = undefined
      },
      closeEdit(updates) {
        let id = this.tag_attrs && this.tag_attrs.id
        this.tag_attrs = undefined
        this.tagsChanged()
        if (this.tagmap[id] && updates && (
          'begin' in updates ||
          'end' in updates ||
          'type' in updates ||
          'channel' in updates)) {
          this.tagmapChanged()
        }
      },
      toTime(time) {
        this.$emit('toTime', time)
      },
      tagmapChanged(opts = { start: this.time }) {
        this.$emit('onTagmapChange', opts)
      },
      tagsChanged(opts) {
        this.$emit('onTagsChange', opts)
      },
      keydown(event) {
        if (['INPUT', 'TEXTAREA', 'SELECT'].indexOf(event.target.nodeName) > -1) {
          return
        }
        if (event.keyCode === 75) {
          return this.newTag()
        }
      }
    },
    computed: {
      filteredTags() {
        let regex = new RegExp(this.search.replace(/\*/g, '.'), 'i')
        return this.search ? this.tags.filter(tag => {
          let k = tag.category.key
          return regex.test(tag.description_short) || (k && regex.test(tag.category.key))
        }) : this.tags
      },
      orderedTags() {
        return this.filteredTags.slice(0).sort((a, b) => {
          let diff = +a.begin - +b.begin
          return Number.isFinite(diff) && a.end ? diff : (-1e10 + (a.ref_id - b.ref_id))
        })
      },
      activableTags() {
        return this.tags.filter(t => t.isActivable())
      },
      unappliedTags() {
        return this.tags.filter(t => !t.isApplied())
      },
      filteredTagCount() {
        return Object.keys(this.tagmap).filter(id => this.tagmap[id]).length
      }
    },
    components: {
      tagBulkApply,
      tagTable,
      tagEdit,
      tagView
    },
    created() {
      document.addEventListener('keydown', this.keydown)
    },
    beforeDestroy() {
      document.removeEventListener('keydown', this.keydown)
    }
  }
</script>
<template src="./index.html"></template>
<style src="./index.less" lang="less"></style>
