<template>
  <layout>
    <div class="auth-wrap">
      <router-link to="/"><img class="auth-wrap-logo" src="/static/img/logos/logo.svg"></router-link>
      <router-view :key="$route.fullPath"></router-view>
    </div>
  </layout>
</template>

<script>

  import layout from 'src/layouts/empty'

  export default {
    components: {
      layout
    }
  }

</script>

<style type="text/css" lang="less">
  .auth-wrap {
    &-logo {
      max-width: 200px;
      margin: 0 auto 2em auto;
      display: block;
    }
  }
</style>
