import api from 'api'

const path = `${api.defaults.basePath}tagging/playlist/custom.m3u8`

function stringifyTagMap(tagmap = {}) {
  let ids = []
  for (let id in tagmap) {
    tagmap[id] && ids.push(id)
  }
  return ids.join(',')
}

function objectToParams(obj = {}) {
  return Object.keys(obj).map(key => `${key}=${obj[key]}`).join('&')
}

export default {
  get(params, tagmap) {
    return `${path}?${objectToParams(params)}&filter_ids=${stringifyTagMap(tagmap)}`
  }
}
