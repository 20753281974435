<template src="./index.tpl.html"></template>

<script>

  import q from 'q'
  import Tag from 'models/Tag'
  import Stage from 'models/Stage'
  import Branch from 'models/Branch'
  import BranchStage from 'models/BranchStage'
  import TagCategory from 'models/TagCategory'

  import cell from './cell'
  import cellDetail from './cellDetail'
  import modal from 'components/modal'
  import deltas from 'components/branchStage/deltas'

  export default {
    props: ['branch'],
    data() {
      return {
        pending: true,
        stages: [],
        tag_lines: [],
        branch_stages: []
      }
    },
    methods: {
      setTagDetails(tagsets) {
        let archived_ids = {},
            all = [].concat.apply([], tagsets.slice(1)),
            lines = [],
            refs = {}

        tagsets[0].forEach(tag => tag.is_archived && (archived_ids[tag.getRefId()] = true))

        // for each stage, set lines
        all.sort((a,b) => +a.begin < +b.begin ? -1 : 1)
          .forEach(tag => {
            let rid = tag.getRefId()
            if (!refs[rid] && !archived_ids[rid]) {
              lines.push((refs[rid] = { rid, tags: [] }))
            }
          })

        tagsets.forEach((tags, i) => {
          tags.forEach(tag => {
            let line = refs[tag.getRefId()]
            if (line) { line.tags[i] = tag }
          })
        })
        this.tag_lines = lines
      }
    },
    created() {
      let bypass = { bypassCache: true, cacheResponse: false },
          request_tags = [],
          request_branch,
          { recording_id } = this.branch

      if (this.branch.parent_id) {
        request_branch = Branch.find(this.branch.parent_id, bypass)
        request_tags.push(Tag.findAll({
          branch_id: this.branch.parent_id,
          recording_id
        }, bypass))
      } else {
        request_tags.push([])
      }

      q.all([
        BranchStage.findAll({ filter: { branch_id: this.branch.id } }),
        TagCategory.findAll(),
        Stage.findAll(),
        request_branch
      ]).spread(branch_stages => {
        this.branch_stages = branch_stages.sort((a, b) => a.id - b.id)

        branch_stages.forEach(branch_stage => {
          request_tags.push(Tag.findAll({
            branch_stage_id: branch_stage.id,
            recording_id
          }, bypass))
        })

        return q.all([
          q.all(request_tags),
          request_branch
        ]).spread(sets => {
          this.stages = branch_stages.map(branch_stage => Stage.get(branch_stage.stage_id))
          this.setTagDetails(sets)
          this.pending = false
        })
      })
    },
    components: {
      deltas,
      modal,
      cell,
      cellDetail
    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./index.less" lang="less"></style>
