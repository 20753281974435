import DSHttpAdapter from 'js-data-http'
import session from 'services/identity/session'
import browseware from 'services/identity/browseware'
import error from 'api/adapters/error'

const headers = {
  'X-OS-Version': '1.0',
  'X-App-Version': process.env.app_version || '1.0',
  'X-App-Platform': 'web',
  'X-RID': browseware.get(),
  get 'X-IID'() {
    return session.get()
  }
}

export default new DSHttpAdapter({

  /*
    Configuration options for axios, the http library used by js-data-http. Configuration options
    for $http, if you're using the http adapter in js-data-angular.
  */
  httpConfig: {
    headers
  },

  /*
    Force use of a trailing slash.
  */
  forceTrailingSlash: true,

  /*
    Serialize the payload into something your server will understand, if necessary.
    resourceConfig is the resource for this adapter operation. data is the payload from the data store.
  */
  // serialize: function (resourceConfig, data) {
  //   return data;
  // },

  /*
    Take the response from your server and transform it into something the
    data store will understand, if necessary. resourceConfig is the resource for this adapter operation.
    data is the response from axios.
  */
  deserialize(resourceName, res) {
    let data = res.data
    if (data && 'count' in data && 'next' in data && 'results' in data) {
      data = data.results
      data._meta = {
        count: res.data.count,
        next: res.data.next,
        previous: res.data.previous
      }
    }
    return data
  },

  /*
    Transform the query string parameters before the request is made. model is the resource
    for this adapter operation. params is the object that will be serialized to the query string.
  */
  queryTransform: function(model, params) {
    if ('orderBy' in params) {
      if (typeof params.orderBy === 'string') {
        params.orderBy = [params.orderBy, 'ASC']
      }
      params.order = params.orderBy
      delete params.orderBy
    }
    return params
  },

  /*
    A suffix to be added to the url path before making a request.
  */
  // suffix: '',

  /*
    A prefix to be added to the url path before making a request.
  */
  // basePath:'',

  /*
    The error handler for all requests
  */
  error,

  /*
    The logging function to use for successful requests. Set to false to disable.
  */
  log: false // function (Default consolelog),

})
