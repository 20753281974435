<template>
  <div>
    <work :work="work" :season="season" :show="show" :master_tags_count="master_tags_count" />
  </div>
</template>

<script>
  import { socket } from 'api'

  import Show from 'models/Show'
  import Season from 'models/Season'
  import Work from 'models/Work'
  import Stage from 'models/Stage'
  import Branch from 'models/Branch'
  import BranchStage from 'models/BranchStage'
  import BranchStageAccess from 'models/BranchStageAccess'
  import Recording from 'models/Recording'
  import RecordingSource from 'models/RecordingSource'
  import TagMaster from 'models/TagMaster'

  import work from 'components/work'

  export default {
    dataResolve: {
      work() {
        return Work.find(this.to.params.id)
      },
      season: ['work', work => work.season_id && Season.find(work.season_id)],
      show: ['season', season => season && Show.find(season.show_id)],
      master_tags_count: ['work', work => TagMaster.getCount(work.id)]
    },
    resolve: {
      recordings: ['work', work => Recording.findAll({ work_id: work.id })],
      sources: () => RecordingSource.findAll(),
      stages: () => Stage.findAll()
    },
    components: {
      work
    },
    created() {
      socket.on('recordings', Recording.manage)
      socket.on('branches', Branch.manage)
      socket.on('branch_stages', BranchStage.manage)
      socket.on('branch_stages_accesses', BranchStageAccess.manage)
    },
    beforeDestroy() {
      socket.off('recordings', Recording.manage)
      socket.off('branches', Branch.manage)
      socket.off('branch_stages', BranchStage.manage)
      socket.off('branch_stages_accesses', BranchStageAccess.manage)
    }
  }

</script>