<script>
  import api from 'api'

  import RecordingSource from 'models/RecordingSource'
  import recording from 'components/recording'
  import layout from 'components/layouts/default'
  import modal from 'components/modal'

  export default {
    props: ['work', 'show', 'season', 'master_tags_count'],
    data() {
      return {
        sources: RecordingSource.getAll(),
        source: RecordingSource.getAll()[0]
      }
    },
    methods: {
      advisoryCount() {
        return (this.work.advisories || []).filter(adv => adv.is_active).length
      },
      rerender() {
        this.$forceUpdate()
      }
    },
    components: {
      modal,
      layout,
      recording
    },
    created() {
      api.hub.$on(`work.${this.work.id}.recordings`, this.rerender)
    },
    beforeDestroy() {
      api.hub.$off(`work.${this.work.id}.recordings`, this.rerender)
    }
  }

</script>

<template src="./index.tpl.html"></template>
<style src="./index.less" lang="less"></style>
