<script>

  import api, { socket } from 'api'

  import RecordingSource from 'models/RecordingSource'
  import Recording from 'models/Recording'

  import recording from 'components/recording'

  export default {
    props: ['work'],
    data() {
      let { source_id } = this.$route.params,
          order = { youtube: 1, disc: 2, vidangel: 3, netflix: 4, amazon: 5 }
      return {
        sources: RecordingSource.getAll().sort((a, b) => (order[a.slug] || 100) - (order[b.slug] || 100)),
        selected_source: RecordingSource.get(source_id)
      }
    },
    methods: {
      recordingsBySource(source) {
        return Recording.filter({ work_id: this.work.id, source_id: source.id })
          .sort((a, b) => a.created_at > b.created_at ? 1 : -1)
      },
      refresh() {
        this.$forceUpdate()
      }
    },
    components: {
      recording
    },
    created() {
      socket.on('recordings', Recording.manage)
      api.hub.$on(`work.${this.work.id}.recordings`, this.refresh)
    },
    beforeDestroy() {
      socket.off('recordings', Recording.manage)
      api.hub.$on(`work.${this.work.id}.recordings`, this.refresh)
    }
  }
</script>
<template src="./index.html"></template>
<style src="./index.less" lang="less"></style>
