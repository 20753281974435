<template>
  <layout>
    <div class="works">
      <h2>Please administer the tagging tool using the company admin tools</h2>
    </div>
  </layout>
</template>

<script>

  import auth from 'services/api/auth'
  import layout from 'components/layouts/default'

  export default {
    resolve: {
      user: () => auth.resolveUser()
    },
    components: {
      layout
    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
