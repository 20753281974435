<script>
  export default {
    props: ['tags', 'tagmap', 'readOnly', 'filter', 'isFullDescription'],
    methods: {
      editTag(tag) {
        this.$emit('editTag', tag)
      },
      viewTag(tag) {
        this.$emit('viewTag', tag)
        this.$forceUpdate()
      },
      toggleTag(tag) {
        this.$emit('toggleTag', tag)
        this.$forceUpdate()
      }
    }
  }
</script>
<template src="./index.tpl.html"></template>
<style src="./index.less" lang="less"></style>
