<template>

  <div class="home">
    <h1>Recent Shows</h1>
    <!-- <poster-list :works="works" ></poster-list> -->
    <!-- <mdbsearch></mdbsearch> -->
    <p>
      <router-link to="/shows/new">Add Show</router-link>
    </p>
  </div>

</template>

<script>

  import Auth from 'services/api/auth'
  import Show from 'models/Show'

  export default {
    data() {
      return {
        works: Show.getAll(),
        user: Auth.user
      }
    },
    resolve: {
      user: () => Auth.resolveUser,
      works: () => Show.findAll()
    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
