<template>

  <div class="empty-wrap">
    <div class="main content-wrap">
      <slot></slot>
    </div>
  </div>

</template>

<script>

  export default {

  }

</script>

<style lang="less">
  .empty-wrap {
    width: 100%;
    .main {
      max-width: 400px;
      margin: 60px auto;
    }
  }

</style>
