
import q from 'q'
import http from 'services/api/adapters/http'
import hls_settings from 'services/hls_settings'
import asyncLoader from 'services/asyncLoad'

export default {
  getDuration(playlist, type) {
    return asyncLoader.loadScript(hls_settings.getVersion().url)
      .then(() => {
        return q.Promise((resolve, reject) => {
          if (!window.Hls.isSupported()) { reject('HLS not supported') }
          let hls = new window.Hls({
                xhrSetup(xhr, url) {
                  if (url && url.indexOf(api.defaults.basePath) > -1 ) {
                    xhr.setRequestHeader('Authorization', http.defaults.httpConfig.headers.Authorization)
                  }
                },
              }),
              video = document.createElement('video')
          hls.loadSource(playlist)
          hls.attachMedia(video)
          video.addEventListener('durationchange', () => resolve(video.duration))
          video.addEventListener('error', reject)
        })
      })
  }
}
