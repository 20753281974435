let isObject = obj => obj === Object(obj)

function formErrorMessage(obj) {
  let msg
  if (isObject(obj) && isObject(obj.error)) {
    msg = obj.error.message
  } else if (isObject(obj) && (typeof obj.error === 'string')) {
    msg = obj.error
  } else if (isObject(obj)) {
    let msgs = []
    Object.forEach(obj, (v, k) => {
      if (k === 'email' && Array.isArray(v) && v[0] === 'This field must be unique.') {
        return msgs.push('This email is already in use.')
      }
      msgs.push(v)
    })
    msg = msgs.join(', ')
  }
  return {
    action: obj.action || 'error',
    message: msg
  }
}

export default resp => {
  if (resp instanceof Error) {
    return Promise.reject(resp)
  }
  let { status, data } = resp,
      error
  if (status >= 400) {
    if (status < 500) {
      switch (status) {
      case 500:
        error = {
          action: 'server_error',
          message: 'Server Error. Try again Later.'
        }
        break
      case -1:
      case 0:
        error = {
          action: 'connect_fail',
          message: 'Unable to connect to the internet.'
        }
        break
      default:
        error = formErrorMessage(data)
      }
      resp.error = error
    }
    return Promise.reject(resp)
  }

  return resp
}
