<template>
  <div class="tag-detail">
    <div class="over" :class="'tagcat-' + tag.category.getRootKey()">
      <span v-for="parent in tag.category.getTree()" :key="parent.id">{{parent.title}}&nbsp;&nbsp;>&nbsp;&nbsp;</span>
      <span>({{tag.category.title_soft || tag.category.title}})</span>
    </div>
    <div>
      <div class="time">
        <div>{{tag.begin | secondsDisplay }}</div>
        <div>{{tag.end | secondsDisplay }}</div>
      </div>

      <span class="short">{{tag.description_short}}</span>
      <span class="gray-lt">{{tag.description_full}}</span>
      <span class="gray">
        <i class="fa fa-fw" :class="tag.type==='audiovisual' ? 'fa-eye-slash' : 'fa-volume-off'"></i>
        <span class="gray" v-if="tag.channel && tag.channel !== 'all'">({{tag.channel}})</span>
      </span>

      <div class="badges">
        <span class="badge" v-if="tag.is_spoiler">spoiler</span>
        <span class="badge" v-if="tag.is_background">background</span>
        <span class="badge" v-if="tag.is_plot_critical">plot critical</span>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    props: ['tag'],
    data() {
      return {}
    },
    computed: {

    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./index.less" lang="less"></style>

<!-- "user_id": "539d0b29-ffa9-4211-9209-352c4be3efdf", "tag_category_id": 15, "description_short": "Other thing", "description_full": "Got it!", "begin": "48.823573", "end": "65.472", "type": "audiovisual", "channel": null, "is_spoiler": false, "is_background": false, "is_plot_critical": false, "is_archived": false, "created_at": "2017-05-02T15:50:34.974Z", "updated_at": "2017-05-02T15:50:34.974Z", "working_id": 1 -->
