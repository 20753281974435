
import * as d3 from 'd3'

import FocusAxis from './axis/axis'
import FocusCursor from './cursor/cursor'
import FocusTags from './tags/tags'

class Focus {
  constructor(parent, dimensions) {
    this.parent = parent
    this.dimensions = dimensions
    this.margin = { top: 0, right: 0, bottom: 0, left: 0 }
    let child_dims = {
      width: this.getWidth(),
      height: this.getHeight()
    }

    let tag_dims = Object.create(child_dims)
    tag_dims.top = 10

    this.g = parent.g.append('g')
      .attr('class', 'focus')
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')')

    this.backg = this.g.append('rect')
      .attr('class', 'focus-backdrop')
      .attr('pointer-events', 'all')
      .attr('cursor', 'move')
      .attr('x', '0')
      .attr('y', '0')
      .attr('width', child_dims.width)
      .attr('height', child_dims.height)

    this.scale = {
      x: d3.scaleLinear().range([0, this.getWidth()]),
      y: d3.scaleBand().range([this.getHeight() - tag_dims.top, 0])
        .domain(['visual', 'audio'])
    }

    this.axis = {
      x: FocusAxis.buildX(this, child_dims, this.scale.x)
    }

    this.tags = FocusTags.build(this, tag_dims, this.scale)

    this.cursor = FocusCursor.build(this, child_dims, this.scale)
  }

  getWidth() {
    return this.dimensions.width - this.margin.left - this.margin.right
  }

  getHeight() {
    return this.dimensions.height - this.margin.top - this.margin.bottom
  }

  setDomain(domain) {
    this.scale.x.domain(domain)
    this.axis.x.update()
  }

  updateTags(tags_data) {
    return this.tags.update(tags_data)
  }

  redrawTags() {
    return this.tags.redraw()
  }

  redrawCursor(time) {
    return this.cursor.redraw(time)
  }

  reportEvent(action, data) {
    return this.parent.reportEvent && this.parent.reportEvent(action, data)
  }
}

export default {
  build(...args) {
    return new Focus(...args)
  }
}
