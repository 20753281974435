import api from 'api'

const Work = api.defineResource({
  name: 'Work',
  endpoint: 'tagging/works',
  cacheResponse: true,
  relations: {
    hasMany: {
      Recording: {
        localField: 'recordings',
        foreignKey: 'work_id'
      },
      WorkAdvisory: {
        localField: 'advisories',
        foreignKey: 'work_id'
      },
      TagMaster: {
        localField: 'master_tags',
        foreignKey: 'work_id'
      }
    },
    belongsTo: {
      Season: {
        localField: 'season',
        localKey: 'season_id'
      }
    }
  },
  methods: {
    getAdvisoryMap() {
      return this.advisories.reduce((set, adv) => {
        set[adv.advisory_id] = adv.is_active
        return set
      }, {})
    },
    getShowTitle() {
      let season = this.season,
          show = season && season.show
      return show && `${show.title} ${season.number}.${this.episode_number}`
    },
    getTitle() {
      let show_title = this.getShowTitle()
      let suffix = this.subtitle ? `: ${this.subtitle}` : ''
      return (show_title ? `${show_title}: ${this.title}` : this.title ) + suffix
    }
  },
  watchChanges: false
})

export default Work
