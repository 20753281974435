
export default function route(path, component, route = {}) {
  if (typeof component === 'string') {
    route.redirect = component
    component = {}
  }
  route.path = path
  route.component = component
  return route
};
